import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Checkbox,
} from "@material-ui/core";
import VirtualizedTable, { ColumnData } from "common/components/VirtualizedTable";
import { IndeterminateCheckBox, CheckBox } from "@material-ui/icons";
import Label from "components/Label";
import { useGenericStyles } from "common/Styles";
import clsx from "clsx";
import TextField from "components/TextField";

export const useNarrowDown = <T,>(data: T[], ...filters: (keyof T)[]) => {
  return useCallback(
    (text: string) => {
      const split = validateNarrowDownKey(text);
      if (split.length === 0) {
        return data;
      }

      return data.filter((i) =>
        split.some((find) =>
          filters.some((filter) => {
            if (i[filter] == null) {
              return false;
            } else {
              return String(i[filter]).indexOf(find) !== -1;
            }
          })
        )
      );
    },
    [data, filters]
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: 500,
  },
  searchText: {
    margin: theme.spacing(1),
    width: `calc(100% - (80px + ${theme.spacing(1)}px * 4))`,
  },
  button: {
    margin: theme.spacing(1),
    width: 80,
  },
  content: {},
  label: {
    width: "100%",
  },
  caption: {
    color: "rgba(0,0,0,0.54)",
  },
  disabled: {
    color: "darkgray",
  },
  underLine: {
    borderBottom: "solid 1px #949494",
  },
}));

interface Props {
  open: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  okButtonText?: string;
  cancelButtonText?: string;
  title: string;
  onClose: (result: any) => void;
  data: any[];
  selectedData?: any[];
  columns: ColumnData[];
  onClickNarrowDown?: (text: string) => any[];
  disabledIfNotSelected?: boolean;
  changeToUnselectedIfNotHit?: boolean;
}

export const SelectDialog = React.memo((props: Props) => {
  const classes = useStyles();

  const genericClasses = useGenericStyles();

  const [text, setText] = useState("");

  const [data, setData] = useState(props.data);

  const [selectedData, setSelectedData] = useState<any[] | undefined>(undefined);

  const handleOnClose = useCallback(() => props.onClose(null), [props]);

  const handleOnCloseInAction = useCallback(() => props.onClose(null), [props]);

  const handleOnSaveInAction = useCallback(() => props.onClose(selectedData), [props, selectedData]);

  const handleOnChangeSearchKey = useCallback((event) => setText(event.target.value), []);

  const handleOnClickSearch = useCallback(() => {
    if (props.onClickNarrowDown) {
      const filtered = [...props.onClickNarrowDown(text)];
      setData(filtered);

      if (props.changeToUnselectedIfNotHit && selectedData) {
        const removeList: any[] = [];

        selectedData.forEach((target) => {
          const hit = filtered.find((value) => value === target);

          if (!hit) {
            removeList.push(target);
          }
        });

        removeList.forEach((value) => {
          const index = selectedData.findIndex((target) => target === value);

          if (index !== -1) {
            selectedData.splice(index, 1);
          }
        });
      }
    }
  }, [props, text, selectedData]);

  const selectedDataInData = useCallback(
    (inside: boolean) => {
      return (
        selectedData?.filter((i) => (inside ? data.findIndex((j) => i === j) !== -1 : data.findIndex((j) => i === j) === -1)) ??
        []
      );
    },
    [data, selectedData]
  );

  const handleOnClickAllOrRemove = useCallback(() => {
    if (selectedData == null) {
      return;
    }

    const selected = selectedDataInData(false);
    if (selectedDataInData(true).length > 0) {
      setSelectedData(selected);
    } else {
      setSelectedData(data.concat(selected));
    }
  }, [data, selectedData, selectedDataInData]);

  const findSelectedDataInData = useCallback(
    (data: any) => {
      return (selectedData?.findIndex((value) => value === data) ?? -1) >= 0;
    },
    [selectedData]
  );

  const headerColumns = useMemo(() => {
    let columns = props.columns;

    if (props.selectedData != null && columns.length - props.columns.length < 1) {
      columns = [...props.columns];

      columns.unshift({
        width: 75,
        label: "",
        headerAlign: "center",
        bodyAlign: "center",
        rendererInHeader: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => {
          const selectedDataList = selectedDataInData(true);
          return (
            <Checkbox
              checked={data.length > 0 && selectedDataList.length > 0}
              checkedIcon={selectedDataList.length === data.length ? <CheckBox /> : <IndeterminateCheckBox />}
              onClick={handleOnClickAllOrRemove}
              color="primary"
            />
          );
        },
        rendererInCell: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
          return <Checkbox checked={findSelectedDataInData(data)} color="primary" />;
        },
      } as ColumnData);
    }

    return columns;
  }, [props.columns, data, props.selectedData, handleOnClickAllOrRemove, selectedDataInData, findSelectedDataInData]);

  const changeCheckBox = useCallback(
    (data: any, checked: boolean) => {
      if (checked) {
        setSelectedData((value) => {
          if (selectedData != null) {
            const index = selectedData.findIndex((value) => value === data);
            value?.splice(index, 1);
          }

          return value ? [...value] : undefined;
        });
      } else {
        setSelectedData((value) => {
          value?.push(data);

          return value ? [...value] : undefined;
        });
      }
    },
    [selectedData]
  );

  const handleOnClickCell = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
      if (props.selectedData == null) {
        if (props.open) {
          props.onClose(data);
        }
      } else {
        changeCheckBox(data, findSelectedDataInData(data));
      }
    },
    [props, changeCheckBox, findSelectedDataInData]
  );

  useEffect(() => {
    if (props.open) {
      setData(props.data);
      setText("");
      setSelectedData(props.selectedData ? [...props.selectedData] : undefined);
    }
  }, [props.data, props.selectedData, props.open]);

  return (
    <Dialog onClose={handleOnClose} open={props.open} fullWidth={true} maxWidth={props.maxWidth}>
      <DialogTitle>{`${props.title}の選択`}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          {props.onClickNarrowDown && (
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <TextField className={classes.searchText} label="絞込条件" value={text} onChange={handleOnChangeSearchKey} />
                <Button className={classes.button} variant="contained" color="primary" onClick={handleOnClickSearch}>
                  絞込
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <VirtualizedTable values={data} rowHeight={48} columns={headerColumns} onClickCell={handleOnClickCell} hover />
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={genericClasses.margin} onClick={handleOnCloseInAction} color="primary">
          {props.cancelButtonText ?? "閉じる"}
        </Button>
        {props.selectedData && (
          <Button
            className={genericClasses.margin}
            onClick={handleOnSaveInAction}
            color="primary"
            disabled={!props.open || (props.disabledIfNotSelected && selectedData?.length === 0)}
          >
            {props.okButtonText ?? "確定"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});

interface LabelWithSelectProps {
  caption: string;
  title?: string;
  text?: string | null;
  className?: string;
  textClassName?: string;
  suffix?: string;
  data: any[];
  columns: ColumnData[];
  onClickNarrowDown?: (text: string) => any[];
  onPreClick?: () => Promise<boolean>;
  onSelected: (result: any) => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  disabled?: boolean;
  placeHolder?: string;
  underLine?: boolean;
}

export const LabelWithSelect = (props: LabelWithSelectProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOnClose = useCallback(
    (result: any) => {
      if (result != null) {
        props.onSelected(result);
      }
      setOpen(false);
    },
    [props]
  );

  const handleOnClick = useCallback(async () => {
    if (props.onPreClick) {
      if (!(await props.onPreClick())) {
        return;
      }
    }

    if (!props.disabled) {
      setOpen(true);
    }
  }, [props]);

  return (
    <>
      <Box className={clsx(props.className, props.underLine && classes.underLine)}>
        <Label
          caption={props.caption}
          text={props.text}
          className={clsx(classes.label)}
          captionClassName={classes.caption}
          textClassName={clsx(props.disabled && classes.disabled)}
          suffix={props.suffix}
          onClick={handleOnClick}
          placeHolder={props.placeHolder}
          disabled={props.disabled}
        />
      </Box>
      <SelectDialog
        open={open}
        onClose={handleOnClose}
        onClickNarrowDown={props.onClickNarrowDown}
        title={props.title ?? props.caption}
        data={props.data}
        columns={props.columns}
        maxWidth={props.maxWidth}
      />
    </>
  );
};

interface WrappedSelectDialogProps {
  children: JSX.Element;
  title: string;
  data: any[];
  selectedData?: any[];
  columns: ColumnData[];
  onClickNarrowDown?: (text: string) => any[];
  onSelected: (result: any) => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  okButtonText?: string;
  cancelButtonText?: string;
}

export const WrappedSelectDialog = (props: WrappedSelectDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleOnClose = useCallback(
    (result: any) => {
      if (result != null) {
        props.onSelected(result);
      }
      setOpen(false);
    },
    [props]
  );

  const handleOnClick = useCallback(() => setOpen(true), []);

  return (
    <>
      <Box onClick={handleOnClick}>{props.children}</Box>
      <SelectDialog
        open={open}
        onClose={handleOnClose}
        onClickNarrowDown={props.onClickNarrowDown}
        title={props.title}
        data={props.data}
        selectedData={props.selectedData}
        columns={props.columns}
        maxWidth={props.maxWidth}
        cancelButtonText={props.cancelButtonText}
        okButtonText={props.okButtonText}
      />
    </>
  );
};

export function validateNarrowDownKey(text: string) {
  if (text == null || text === "") {
    return [];
  }

  let split = text.replaceAll("　", " ").split(" ");

  if (split.length === 0) {
    return [];
  }

  return split.filter((value) => value !== "");
}
