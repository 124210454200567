import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Menu, MenuItem } from "@material-ui/core";
import { IconButtonEx } from "./Wrapping";
import { AppProvider } from "App";
import { msal } from "common/GoodManagementUserAgentApplication";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      flexGrow: 1,
    },
  })
);

type Props = {
  title: string;
};

export const Header = (props: Props) => {
  const classes = useStyles();
  const loginUserName = AppProvider.useGlobalState("loginUserName");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    msal.logoutRedirect();
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {props.title}
        </Typography>
        <div>
          <IconButtonEx color="inherit" onClick={handleClick} title={loginUserName}>
            <AccountCircle />
            {loginUserName}
          </IconButtonEx>
          <Menu id="userMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
