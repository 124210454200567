import React, { useState, useCallback } from "react";
import Api, { ApiUrl } from "common/Api";
import { useFetch } from "hooks/useFetch";
import { LoadingMode, useLoadingElement } from "components/Loading";
import { AppBar, Container, createStyles, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { InviteUserModel } from "models/InviteUserModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      height: "100vh",
    },
    container: {
      paddingTop: theme.spacing(2),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      flexGrow: 1,
    },
    loadingPageBody: {
      height: 600,
    },
  })
);

export const UserInviteComplete = () => {
  const classes = useStyles();
  const location = useLocation();
  const [message, setMessage] = useState<string>();

  const load = useCallback(async (): Promise<void> => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (!token || token.length !== 32) {
      setMessage("URLが不正です。");
      return;
    }

    try {
      const result = await Api.post<InviteUserModel>(ApiUrl.UserInviteComplete(), { token: token });
      setMessage(`${result.data.userId} のユーザー登録が完了しました。`);
    } catch (e: any) {
      if (e.isAxiosError && e.response) {
        if (e.response.status === 404) {
          setMessage("招待情報が見つかりませんでした。");
        } else {
          setMessage("エラーステータス: " + e.response.status);
        }
      } else {
        setMessage(e.message);
      }
    }
  }, [location.search]);

  const fetchResult = useFetch(load);
  const loadingElement = useLoadingElement(classes.loadingPageBody, LoadingMode.Circular, fetchResult);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            ユーザー招待完了画面
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <Toolbar />
        <Container maxWidth="lg" className={classes.container}>
          {loadingElement ?? (
            <Typography variant="subtitle1" component="div">
              {message}
            </Typography>
          )}
        </Container>
      </main>
    </div>
  );
};
