import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TenantLicenseModel } from "models/TenantLicenseModel";
import { TenantLicenseTableRow, Events as TableRowEvents } from "./TenantLicenseTableRow";

type Props = {
  licenses: TenantLicenseModel[];
} & TableRowEvents;

export const TenantLicenseTable = (props: Props) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>プラン名称</TableCell>
            <TableCell style={{ width: 150 }}>ライセンス</TableCell>
            <TableCell style={{ width: 150 }}>ライセンス数</TableCell>
            <TableCell style={{ width: 180 }}>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.licenses.map((license, index) => (
            <TenantLicenseTableRow
              key={index}
              license={license}
              onEditLicense={props.onEditLicense}
              onDeleteLicense={props.onDeleteLicense}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
