import React from "react";
import { Route as RouterRoute, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { RouteDef, RouteUrl } from "common/Routing";
import { Url, QueryParameters, PathParameters } from "utils/Url";
import { Omit } from "@material-ui/types";

type RouteProps = {
  route: RouteDef;
  pathParameters?: PathParameters;
  queryParameters?: QueryParameters;
};

export const navigate = (route: RouteDef | RouteUrl, parameters?: QueryParameters): void => {
  const url = Url.create(route.path, parameters);
  console.log(url);
};

export const Route = (props: RouteProps) => {
  const { route, ...remain } = props;
  const exact = route.exact ?? false;
  return <RouterRoute path={route.path} component={route.component} exact={exact} {...remain} />;
};

export const LinkTo = React.forwardRef<any, Omit<RouteProps & RouterLinkProps, "to">>((props, ref) => {
  const { route, pathParameters, queryParameters, ...remain } = props;
  const path = pathParameters ? Url.formatPath(route.path, pathParameters) : route.path;
  const to = Url.create(path, queryParameters);
  return <RouterLink ref={ref} to={to} {...remain} />;
});
