import { Button, IconButton, Tooltip } from "@material-ui/core";
import React from "react";

export const ButtonEx = React.memo((props: any) => {
  return <Button {...props}></Button>;
});

export const IconButtonEx = React.memo((props: any) => {
  const { title, ...other } = props;

  return (
    <Tooltip title={title}>
      <IconButton {...other}></IconButton>
    </Tooltip>
  );
});
