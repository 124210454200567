import React, { useState, useRef, useCallback, useEffect } from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { ButtonEx } from "./Wrapping";

type StyleProps = {
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
};

const useStyles = makeStyles((theme) => ({
  img: (props?: StyleProps) => {
    let width = props?.width;
    let height = props?.height;

    if (props?.width !== undefined && props?.height === undefined) {
      // widthのみ指定の場合、heightはautoでアスペクト比を維持
      height = "auto";
    } else if (props?.height !== undefined && props?.width === undefined) {
      // heightのみ指定の場合、widthはautoでアスペクト比を維持
      width = "auto";
    }

    return {
      width: width,
      height: height,
      minHeight: props?.minHeight,
      minWidth: props?.minWidth,
      maxHeight: props?.maxHeight,
      maxWidth: props?.maxWidth,
    };
  },
  dnd: (props?: StyleProps) => ({
    minHeight: props?.minHeight,
    minWidth: props?.minWidth,
    maxHeight: props?.maxHeight,
    maxWidth: props?.maxWidth,
    width: "100%",
    border: "5px dotted rgba(0, 0, 0, 0.3)",
    padding: "25px 40px",
    textAlign: "center",
    borderRadius: "10px",
    lineHeight: "1.7",
    fontSize: "18px",
    "&[data-drag='true']": {
      borderColor: "rgba(255, 0, 0, 0.3)",
    },
  }),
}));

type Props = {
  controlId: string;
  style?: StyleProps;
  image?: Blob;
  onChangeImage: (image?: Blob) => void;
};

export function AttachmentImageFile(props: Props) {
  const classes = useStyles(props.style);
  const fileInput = useRef<HTMLInputElement>(null);
  const [base64Image, setBase64Image] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const onChangeImageCallback = props.onChangeImage;

  const renderImage = useCallback(
    (image: Blob) => {
      var reader = new FileReader();
      reader.onload = () => {
        setBase64Image(reader.result as string);
        onChangeImageCallback(image);
      };
      reader.readAsDataURL(image);
    },
    [onChangeImageCallback]
  );

  useEffect(() => {
    if (props.image) {
      renderImage(props.image);
    }
  }, [props.image, renderImage]);

  const loadFile = useCallback(
    (fileList: FileList | null) => {
      if (!fileList || !fileList.length) {
        return;
      }

      const file = fileList.item(0);
      if (!file) {
        return;
      }

      renderImage(file);
    },
    [renderImage]
  );

  const handleSelectFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      loadFile(event.currentTarget.files);
    },
    [loadFile]
  );

  const handleClearFile = useCallback(() => {
    setBase64Image("");
    onChangeImageCallback();
    fileInput.current!.value = "";
  }, [onChangeImageCallback]);

  const handleDropFile = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);
      loadFile(event.dataTransfer.files);
    },
    [loadFile]
  );

  const handleChangeDragState = useCallback((event: React.DragEvent<HTMLDivElement>, isDragging) => {
    event.preventDefault();
    setIsDragging(isDragging);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id={props.controlId}
            multiple
            type="file"
            onChange={handleSelectFile}
            ref={fileInput}
          />

          <div
            className={classes.dnd}
            data-drag={isDragging}
            onDragEnter={(e) => handleChangeDragState(e, true)}
            onDragLeave={(e) => handleChangeDragState(e, false)}
            onDragOver={(e) => handleChangeDragState(e, true)}
            onDrop={(e) => handleDropFile(e)}
          >
            {base64Image && <img src={base64Image} className={classes.img} alt="サービスイメージ画像" />}
            {!base64Image && <Typography variant="subtitle1">ファイルをドロップ</Typography>}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <label htmlFor={props.controlId}>
                <ButtonEx variant="contained" color="primary" component="span">
                  ファイルを選択
                </ButtonEx>
              </label>
            </Grid>
            <Grid item>
              <ButtonEx variant="contained" color="secondary" component="span" onClick={() => handleClearFile()}>
                クリア
              </ButtonEx>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
