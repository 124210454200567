import { Select, SelectProps } from "@material-ui/core";
import React from "react";

export default (props: SelectProps) => {
  const { value, ...other } = props;

  return (
    <Select {...other} value={value ?? ""}>
      {props.children}
    </Select>
  );
};
