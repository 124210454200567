import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { Design } from "common/Constant";

const useStyles = makeStyles((theme) => ({
  caption: {
    transform: "translate(0px, -1px) scale(0.75)",
    transformOrigin: "top left",
    color: (props: any) => (props.disabled ? theme.palette.text.disabled : theme.palette.text.primary),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  box: {
    height: (props: any) => props.height,
    minHeight: 47,
  },
  text: {
    wordBreak: "break-all",
    transform: "translate(0px, -4px) scale(1)",
    color: (props: any) => (props.disabled ? theme.palette.text.disabled : theme.palette.text.primary),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noSelected: {
    wordBreak: "break-all",
    color: "darkgray",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  underLine: {
    borderBottom: "solid 1px #949494",
  },
}));

interface Props {
  caption: string;
  text?: string | string[] | null;
  className?: string;
  captionClassName?: string;
  textClassName?: string;
  suffix?: string;
  onClick?: () => void;
  placeHolder?: string;
  disabled?: boolean;
  underLine?: boolean;
}

const Label = (props: Props) => {
  const height = useMemo(() => {
    if (Array.isArray(props.text)) {
      return Design.componentUnitHeight - 1 + 24 * (props.text.length - 1);
    } else {
      return Design.componentUnitHeight - 1;
    }
  }, [props.text]);

  const classes = useStyles({ height: height, disabled: props.disabled });

  const text = useMemo(() => {
    if (props.text == null) {
      return props.placeHolder == null ? "" : props.placeHolder;
    } else {
      return props.text + (props.suffix == null ? "" : props.suffix);
    }
  }, [props.text, props.suffix, props.placeHolder]);

  const handleOnClick = useCallback(() => props.onClick?.(), [props.onClick]);

  return (
    <Box className={clsx(classes.box, props.className, props.underLine && classes.underLine)} onClick={handleOnClick}>
      <Typography className={clsx(classes.caption, props.captionClassName)}>{props.caption}</Typography>
      {Array.isArray(props.text) ? (
        props.text.map((value: string, index: number) => {
          return (
            <Typography
              key={value + index}
              className={clsx(props.text == null ? classes.noSelected : classes.text, props.textClassName)}
            >
              {value}
            </Typography>
          );
        })
      ) : (
        <Typography className={clsx(props.text == null ? classes.noSelected : classes.text, props.textClassName)}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(Label);
