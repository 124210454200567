import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { UserModel } from "models/UserModel";
import { PlanModel } from "models/PlanModel";
import { UserTableRow } from "./UserTableRow";
import { CallbackWithState } from "hooks/useFetch";

type Props = {
  users: UserModel[];
  onAssignPlan: CallbackWithState<(user: UserModel) => void>;
  onUnassignPlan: CallbackWithState<(user: UserModel, plan: PlanModel) => void>;
  onEditUser: CallbackWithState<(user: UserModel) => void>;
  onDeleteUser: CallbackWithState<(user: UserModel) => void>;
};

export const UserTable = (props: Props) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 600 }}>ユーザーID</TableCell>
            <TableCell>ユーザー名</TableCell>
            <TableCell style={{ width: 250 }}>割り当てプラン</TableCell>
            <TableCell style={{ width: 180 }}>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users.map((user) => (
            <UserTableRow
              key={user.userId}
              user={user}
              onAssignPlan={props.onAssignPlan}
              onUnassignPlan={props.onUnassignPlan}
              onEditUser={props.onEditUser}
              onDeleteUser={props.onDeleteUser}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
