import { ColumnData, EditType } from "common/components/VirtualizedTable";

export const editButton: ColumnData = {
  width: 80,
  label: "",
  bodyAlign: "center",
  editType: EditType.EditButton,
  widthType: "fix",
};

export const deleteButton: ColumnData = {
  width: 80,
  label: "",
  headerAlign: "center",
  bodyAlign: "center",
  editType: EditType.DeleteButton,
  widthType: "fix",
};

export const referenceButton: ColumnData = {
  width: 80,
  label: "",
  bodyAlign: "center",
  editType: EditType.ReferenceButton,
  widthType: "fix",
};
