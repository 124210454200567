import React from "react";

export interface Pair {
  value: any;
  text: string;
}

export interface ComboItem {
  value: any;
  text: string;
}

export interface ResponseCode {
  responseCode: string;
}

export interface ResponseValue<T> {
  value: T;
}

export class CancelEventArgs {
  cancel: boolean = false;
}

export function swap<T>(array: T[], x: number, y: number) {
  array[x] = [array[y], (array[y] = array[x])][0];
  return array;
}

export function generalComparsion(a: any, b: any) {
  if (a == null && b == null) {
    return 0;
  }

  if (a == null) {
    return -1;
  }

  if (b == null) {
    return 1;
  }

  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
}

export function sortWithSet<T>(
  data: Array<T>,
  setData: React.Dispatch<React.SetStateAction<Array<T>>>,
  propertyName?: keyof T,
  asc: boolean = true,
  convert?: (data: any, rowData: any) => string
) {
  if (convert) {
    data.sort(
      (a, b) =>
        generalComparsion(
          convert(propertyName ? a[propertyName] : null, a),
          convert(propertyName ? b[propertyName] : null, b)
        ) * (asc ? 1 : -1)
    );
  } else {
    data.sort(
      (a, b) => generalComparsion(propertyName ? a[propertyName] : null, propertyName ? b[propertyName] : null) * (asc ? 1 : -1)
    );
  }
  setData([...data]);
}

export function replaceCRLF(value: string) {
  if (value == null) {
    return <></>;
  }
  return (
    <div>
      {value.split("\n").map((str, index) => (
        <React.Fragment key={index}>
          {str}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
}

export type Uuid = string;

export function generateUuid() {
  let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case "x":
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case "y":
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join("");
}
