import { ComboItem } from "./HandleUtility";

export class Design {
  static readonly mainFramePadding: number = 24;

  static readonly headerHeight: number = 64;

  static readonly margin: number = 8;

  static readonly rowHeight = 32;

  static readonly scheduleMinHeight = Design.rowHeight * 10;

  static readonly dialogTopHeight = 32 + 64 + Design.margin;

  static readonly dialogBottomHeight = Design.margin + 52.5 + Design.margin * 2 + 32;

  static readonly unknownGap = 8;

  static readonly componentUnitHeight = 48;

  static readonly topButtonHeight = 44.5;
}

export enum TenantCategory {
  Partner = 1,
  Vendor = 2,
  Management = 4,
}

export const TenantClass = {
  UnClass: 0,
  Agency: 1,
  Sale: 2,
  EndUser: 3,
  Other: 4,
};

export const TenantCategoryItems: ComboItem[] = [
  { text: "パートナー", value: TenantCategory.Partner },
  { text: "ベンダー", value: TenantCategory.Vendor },
  { text: "運営", value: TenantCategory.Management },
];

export const TenantClassItems: ComboItem[] = [
  { text: "取次店", value: TenantClass.Agency },
  { text: "販売", value: TenantClass.Sale },
  { text: "エンドユーザー", value: TenantClass.EndUser },
  { text: "その他", value: TenantClass.Other },
];

// カテゴリの値をマイナスとして扱う
export const DisplayTenantCategories: ComboItem[] = [
  ...TenantCategoryItems.map((value) => ({ ...value, value: -value.value })),
  ...TenantClassItems,
];

export const AreaComboItem: ComboItem[] = [
  { text: "未選択", value: 0 },
  { text: "北海道", value: 1 },
  { text: "青森県", value: 2 },
  { text: "岩手県", value: 3 },
  { text: "宮城県", value: 4 },
  { text: "秋田県", value: 5 },
  { text: "山形県", value: 6 },
  { text: "福島県", value: 7 },
  { text: "茨城県", value: 8 },
  { text: "栃木県", value: 9 },
  { text: "群馬県", value: 10 },
  { text: "埼玉県", value: 11 },
  { text: "千葉県", value: 12 },
  { text: "東京都", value: 13 },
  { text: "神奈川県", value: 14 },
  { text: "新潟県", value: 15 },
  { text: "富山県", value: 16 },
  { text: "石川県", value: 17 },
  { text: "福井県", value: 18 },
  { text: "山梨県", value: 19 },
  { text: "長野県", value: 20 },
  { text: "岐阜県", value: 21 },
  { text: "静岡県", value: 22 },
  { text: "愛知県", value: 23 },
  { text: "三重県", value: 24 },
  { text: "滋賀県", value: 25 },
  { text: "京都府", value: 26 },
  { text: "大阪府", value: 27 },
  { text: "兵庫県", value: 28 },
  { text: "奈良県", value: 29 },
  { text: "和歌山県", value: 30 },
  { text: "鳥取県", value: 31 },
  { text: "島根県", value: 32 },
  { text: "岡山県", value: 33 },
  { text: "広島県", value: 34 },
  { text: "山口県", value: 35 },
  { text: "徳島県", value: 36 },
  { text: "香川県", value: 37 },
  { text: "愛媛県", value: 38 },
  { text: "高知県", value: 39 },
  { text: "福岡県", value: 40 },
  { text: "佐賀県", value: 41 },
  { text: "長崎県", value: 42 },
  { text: "熊本県", value: 43 },
  { text: "大分県", value: 44 },
  { text: "宮崎県", value: 45 },
  { text: "鹿児島県", value: 46 },
  { text: "沖縄県", value: 47 },
];
