import { TextFieldProps } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import TextField from "components/TextField";

const DigitsField = (props: TextFieldProps & { allowMinus?: boolean; max?: number; min?: number; maxLength?: number }) => {
  const { onChange, max, min, allowMinus, inputProps, ...other } = props;

  const maxValue = useMemo(() => max ?? Number.MAX_SAFE_INTEGER, [max]);
  const minValue = useMemo(() => min ?? Number.MIN_SAFE_INTEGER, [min]);

  if (maxValue > Number.MAX_SAFE_INTEGER || minValue < Number.MIN_SAFE_INTEGER) {
    throw new Error(
      "DigitsFieldのmin又はmaxの指定が範囲外です。\n" +
        Number.MIN_SAFE_INTEGER +
        "～" +
        Number.MAX_SAFE_INTEGER +
        "の間で指定してくださ。"
    );
  }

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      const inputValue = event.target.value;

      if (allowMinus) {
        const regex = /^-?\d*$/g;
        if (!event.target.value.match(regex)) {
          return;
        }
      } else {
        event.target.value = event.target.value.replaceAll(/\D/g, "");
      }

      if (Number(event.target.value) > maxValue) {
        event.target.value = maxValue.toString();
      }
      if (Number(event.target.value) < minValue) {
        event.target.value = minValue.toString();
      }

      if (inputValue === event.target.value) {
        onChange?.(event);
      }
    },
    [maxValue, minValue, allowMinus, onChange]
  );

  return (
    <TextField
      {...other}
      onChange={handleOnChange}
      inputProps={{ inputMode: "numeric", maxLength: props.maxLength ?? 9, ...inputProps }}
    />
  );
};

export default React.memo(DigitsField);

export const TextFieldWithFilter = React.memo((props: TextFieldProps & { filter: string | RegExp }) => {
  const { onChange, ...other } = props;

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      const inputValue = event.target.value;
      event.target.value = event.target.value.replaceAll(props.filter, "");
      if (inputValue === event.target.value) {
        onChange?.(event);
      }
    },
    [onChange, props.filter]
  );

  return <TextField {...other} onChange={handleOnChange} />;
});

export const DigitsWithHyphenField = React.memo((props: TextFieldProps) => {
  return <TextFieldWithFilter {...props} filter={/[^0-9\\-]/g} />;
});
