import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ServiceModel } from "models/ServiceModel";
import { ServiceTableRow, Events as TableRowEvents } from "./ServiceTableRow";

type Props = {
  services: ServiceModel[];
  tenantGuid: string;
} & TableRowEvents;

export const ServiceTable = (props: Props) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>サービス名称</TableCell>
            <TableCell style={{ width: 300 }}>提供アプリ</TableCell>
            <TableCell style={{ width: 140 }}>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.services.map((service) => (
            <ServiceTableRow
              key={service.guid}
              service={service}
              tenantGuid={props.tenantGuid}
              onEditService={props.onEditService}
              onDeleteService={props.onDeleteService}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
