import React, { useState, useEffect, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Api, { ApiUrl } from "common/Api";
import {
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { useMessageBox } from "hooks/useMessageBox";
import { ButtonEx } from "components/Wrapping";
import { useAlertAdd } from "components/AlertList";
import { useExecute } from "hooks/useFetch";
import { Utility } from "utils/Utility";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    domainTextDiv: {
      position: "relative",
    },
    domainText: {
      position: "absolute",
      bottom: "7px",
    },
  })
);

type Props = {
  open: boolean;
  onClose: (result?: any) => void;
  tenantGuid: string;
};

export const UserCreateForm = (props: Props) => {
  const [userId, setUserId] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [manager, setManager] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();
  const classes = useStyles();

  const onCloseCallback = props.onClose;

  const onShowForm = useCallback(() => {
    setUserId("");
    setUserName("");
    setPassword("");
    setManager(false);
  }, []);

  useEffect(() => {
    if (!props.open) {
      return;
    }

    onShowForm();
  }, [onShowForm, props.open]);

  useEffect(() => {
    const valid = userId !== "" && userName !== "" && password !== "";
    setIsValid(valid);
  }, [userId, userName, password]);

  const register = useCallback(
    async (
      unmounted: { value: boolean },
      param: { tenantGuid: string; userId: string; userName: string; password: string; manager: boolean }
    ): Promise<void> => {
      try {
        const result = await Api.post(ApiUrl.UserCreate(param.tenantGuid), {
          userId: param.userId,
          name: param.userName,
          password: param.password,
          manager: param.manager,
        });

        const isErrorResponse = Utility.isErrorResonse(result.data);
        if (!isErrorResponse) {
          alertAdd("info", "ユーザーを登録しました");
        }

        if (unmounted.value) {
          return;
        }

        if (isErrorResponse) {
          if (result.data.code === 301) {
            await messagebox.error("ユーザ招待エラー", `${param.userId} は登録済みです`);
          }
        } else {
          onCloseCallback(result);
        }
      } catch (e: any) {
        if (e.isAxiosError && e.response && e.response.data) {
          if (e.response.status === 400 && e.response.data.code === 202) {
            await messagebox.error("ユーザー作成エラー", "パスワードの複雑さが要件を満たしていません");
          } else if (e.response.status === 409 && e.response.data.code === 201) {
            await messagebox.error("ユーザー作成エラー", "既に使用されているIDです");
          }
        }
      }
    },
    [messagebox, onCloseCallback, alertAdd]
  );

  const [executeRegister, registerInProcess] = useExecute(register);

  const handleRegister = useCallback(() => {
    executeRegister({
      tenantGuid: props.tenantGuid,
      userId: userId,
      userName: userName,
      password: password,
      manager: manager,
    });
  }, [executeRegister, password, props.tenantGuid, userId, userName, manager]);

  return (
    <Dialog open={props.open} maxWidth="sm">
      <DialogTitle id="form-dialog-title">ユーザー登録</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="id"
              label="ユーザーID"
              inputProps={{ maxLength: 64 }}
              onChange={(e) => setUserId(e.target.value)}
              style={{ width: "100%" }}
              autoFocus
            />
          </Grid>
          <Grid item xs={6} className={classes.domainTextDiv}>
            <Typography className={classes.domainText} variant="inherit">
              @koeigoodoutlook.onmicrosoft.com
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="ユーザー名"
              inputProps={{ maxLength: 256 }}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password"
              type="password"
              label="パスワード"
              inputProps={{ minLength: 8, maxLength: 256 }}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              helperText="アルファベットの大文字、小文字、数字、記号の3つ以上を使用してください"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={manager} onChange={(e) => setManager(e.target.checked)} />}
              label="管理者"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonEx color="primary" variant="contained" onClick={handleRegister} disabled={!isValid || registerInProcess}>
          登録
        </ButtonEx>
        <ButtonEx variant="contained" onClick={() => props.onClose()}>
          閉じる
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
