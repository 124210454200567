type Parameter = string | number;
type OptionalParameter = string | number | null | undefined;
export type PathParameters = { [index: string]: Parameter };
export type QueryParameters = { [index: string]: OptionalParameter };

const removeLastSlash = (text: string): string => {
  return text.endsWith("/") ? text.slice(0, -1) : text;
}

/**
 * オブジェクトをクエリストリングに変換。値がnullまたはundefinedのプロパティは除外する。
 * @example
 * { id: 1, name: "name", optional: null } -> "?id=1&name=name"
 * @param parameters 変換するオブジェクト
 */
const toQueryParameterString = (parameters: QueryParameters): string => {
  const encode = (p: OptionalParameter): string => encodeURIComponent(p!.toString());
  return Object.entries(parameters)
    .filter(([, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${key}=${encode(value)}`)
    .join("&");
};

export class Url {
  static create(path: string, parameters?: QueryParameters): string {
    let url = encodeURI(path);
    if (parameters) {
      return removeLastSlash(url) + "?" + toQueryParameterString(parameters);
    }
    return url;
  };

  static formatPath(path: string, parameters: PathParameters): string {
    return path.replace(/\/:([^/]+)/g, (_match, name) => (
      "/" + String(parameters[name])
    ));
  }
}
