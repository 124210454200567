import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from "@material-ui/core";
import { Info as IconInfo, Help as IconConfirm, Warning as IconWarning, Error as IconError } from "@material-ui/icons";
import { ButtonEx } from "./Wrapping";

export enum ButtonType {
  OK = 1,
  Cancel = 2,
  Yes = 4,
  No = 8,
  OKCancel = OK | Cancel,
  YesNo = Yes | No,
}

type MessageTypeDef = {
  icon: JSX.Element;
  buttonType: ButtonType;
};

type MessageTypes = {
  [index: string]: MessageTypeDef;
};

const MessageTypes: MessageTypes = {
  info: {
    icon: <IconInfo color="primary" />,
    buttonType: ButtonType.OK,
  },
  confirm: {
    icon: <IconConfirm color="primary" fontSize="large" />,
    buttonType: ButtonType.YesNo,
  },
  warning: {
    icon: <IconWarning color="secondary" />,
    buttonType: ButtonType.OK,
  },
  error: {
    icon: <IconError color="secondary" />,
    buttonType: ButtonType.OK,
  },
};
//type MessageType = keyof typeof MessageTypes;
export type MessageType = "info" | "confirm" | "warning" | "error";

type Props = {
  isOpen: boolean;
  type: MessageType;
  buttonType?: ButtonType;
  title?: string;
  message: string;
  onClose: (result: boolean) => void;
};

export const MessageBox = (props: Props) => {
  const messageTypeDef = MessageTypes[props.type];
  const buttonType = props.buttonType ?? messageTypeDef.buttonType;

  const isVisible = (checkButtonType: ButtonType): boolean => {
    return (buttonType & checkButtonType) === checkButtonType;
  };

  const handleClose = (result: boolean): void => {
    props.onClose(result);
  };

  return (
    <Dialog open={props.isOpen}>
      {props.title && <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>}
      <DialogContent style={{ minWidth: 444 }}>
        <Grid container>
          <Grid item xs={2}>
            {messageTypeDef.icon}
          </Grid>
          <Grid item xs={10}>
            <Typography style={{ whiteSpace: "pre-wrap" }}>{props.message}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isVisible(ButtonType.OK) && (
          <ButtonEx color="primary" variant="contained" onClick={() => handleClose(true)}>
            OK
          </ButtonEx>
        )}
        {isVisible(ButtonType.Cancel) && (
          <ButtonEx variant="contained" onClick={() => handleClose(false)}>
            キャンセル
          </ButtonEx>
        )}
        {isVisible(ButtonType.Yes) && (
          <ButtonEx color="primary" variant="contained" onClick={() => handleClose(true)}>
            はい
          </ButtonEx>
        )}
        {isVisible(ButtonType.No) && (
          <ButtonEx variant="contained" onClick={() => handleClose(false)}>
            いいえ
          </ButtonEx>
        )}
      </DialogActions>
    </Dialog>
  );
};
