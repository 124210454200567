import React, { useState, useEffect, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { PlanModel } from "models/PlanModel";
import Api, { ApiUrl } from "common/Api";
import { DialogTitle, Grid, Typography, TextField } from "@material-ui/core";
import { CheckList, CheckListValueConstraint } from "components/CheckList";
import { ButtonEx } from "components/Wrapping";
import { useExecute } from "hooks/useFetch";
import { useAlertAdd } from "components/AlertList";
import { ServiceModel } from "models/ServiceModel";

type Props = {
  open: boolean;
  onClose: (result?: any) => void;
  plan?: PlanModel;
};

type ServiceCheckListValue = ServiceModel & CheckListValueConstraint;

export const PlanForm = (props: Props) => {
  const [planName, setPlanName] = useState<string>("");
  const [services, setServices] = useState<ServiceCheckListValue[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  const alertAdd = useAlertAdd();

  const isEditMode = props.plan != null;
  const onCloseCallback = props.onClose;

  const isAssignService = useCallback(
    (guid: string): boolean => {
      return props.plan !== undefined && props.plan?.services.some((service) => service.guid === guid);
    },
    [props.plan]
  );

  const onShowForm = useCallback(async () => {
    if (isEditMode) {
      setPlanName(props.plan!.name);
    } else {
      setPlanName("");
    }

    const responseServices = await Api.get<ServiceModel[]>(ApiUrl.Services());

    const services = responseServices.data.map((service) => {
      return { ...service, checked: isAssignService(service.guid) };
    });
    setServices(services);
  }, [isAssignService, isEditMode, props.plan]);

  useEffect(() => {
    if (!props.open) {
      return;
    }

    onShowForm();
  }, [onShowForm, props.open]);

  useEffect(() => {
    const valid = planName !== "";
    setIsValid(valid);
  }, [planName]);

  const register = useCallback(
    async (unmounted: { value: boolean }, param: { planName: string; assignServiceIds: string[] }): Promise<void> => {
      const result = await Api.post<ServiceModel>(ApiUrl.Plan(), {
        name: param.planName,
        assignServiceIds: param.assignServiceIds,
      });

      alertAdd("info", "プランを登録しました");

      if (unmounted.value) {
        return;
      }

      onCloseCallback(result);
    },
    [alertAdd, onCloseCallback]
  );

  const [executeRegister, registerInProcess] = useExecute(register);

  const update = useCallback(
    async (
      unmounted: { value: boolean },
      param: { planGuid: string; planName: string; assignServiceIds: string[] }
    ): Promise<void> => {
      const result = await Api.put<PlanModel>(ApiUrl.Plan(param.planGuid), {
        name: param.planName,
        assignServiceIds: param.assignServiceIds,
      });

      alertAdd("info", "プランを更新しました");

      if (unmounted.value) {
        return;
      }

      onCloseCallback(result);
    },
    [alertAdd, onCloseCallback]
  );

  const [executeUpdate, updateInProcess] = useExecute(update);

  const handleRegister = useCallback(() => {
    const assignServiceIds = services.filter((service) => service.checked === true).map((service) => service.guid);
    if (isEditMode) {
      executeUpdate({
        planGuid: props.plan!.guid,
        planName: planName,
        assignServiceIds: assignServiceIds,
      });
    } else {
      executeRegister({
        planName: planName,
        assignServiceIds: assignServiceIds,
      });
    }
  }, [executeRegister, executeUpdate, isEditMode, props.plan, planName, services]);

  const onCheckRow = useCallback(
    (index: number) => {
      const newServices = [...services];
      newServices[index].checked = !newServices[index].checked;
      setServices(newServices);
    },
    [services]
  );

  const onCheckAll = useCallback(
    (checked: boolean) => {
      const newServices = [...services];
      for (let i = 0; i < newServices.length; i++) {
        newServices[i].checked = checked;
      }
      setServices(newServices);
    },
    [services]
  );

  return (
    <Dialog open={props.open} maxWidth="xs">
      <DialogTitle id="form-dialog-title">{isEditMode ? "プラン編集" : "プラン登録"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="プラン名称"
              defaultValue={planName}
              inputProps={{ maxLength: 256 }}
              onChange={(e) => setPlanName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">提供サービス</Typography>
            <CheckList
              values={services}
              columns={[{ key: "name", title: "サービス名" }]}
              onCheckRow={onCheckRow}
              onCheckAll={onCheckAll}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonEx
          color="primary"
          variant="contained"
          onClick={handleRegister}
          disabled={!isValid || registerInProcess || updateInProcess}
        >
          登録
        </ButtonEx>
        <ButtonEx variant="contained" onClick={() => props.onClose()}>
          閉じる
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
