import React, { useCallback, useEffect, useMemo } from "react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { CssBaseline } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { CancelTokenSource } from "axios";
import Api, { ApiUrl } from "common/Api";
import { msal } from "common/GoodManagementUserAgentApplication";
import { Routing } from "common/Routing";
import AlertList, { AlertProvider, useAlertAdd } from "components/AlertList";
import { Header } from "components/Header";
import { Menu } from "components/Menu";
import { Route } from "components/Navigate";
import { IAction, connect } from "components/connect";
import { abortMessage, useFetch } from "hooks/useFetch";
import { AccountModel } from "models/AccountModel";
import { UserInviteComplete } from "pages/UserInviteComplete";
import { BrowserRouter, Route as RouterRoute, Switch, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      height: "100vh",
    },
    container: {
      paddingTop: theme.spacing(2),
    },
  })
);

/**
 * <Switch>の直下にはRouteコンポーネントかReactフラグメントを返す(レンダリングする？)コンポーネントしか書けない。
 * Routeコンポーネントを返す(レンダリングする？)カスタムコンポーネントを列挙した場合、先頭だけが有効になる。
 * Reactフラグメントで囲んでやればカスタムコンポーネントを列挙した場合でも正しく動作する。
 *
 * Custom route in `<Switch/>` v4 doesn't seem to work
 * https://github.com/ReactTraining/react-router/issues/4576
 * Switch component incorrectly matching routes
 * https://github.com/ReactTraining/react-router/issues/5261
 * React Router - 404 page always showing due to custom route component
 * https://stackoverflow.com/questions/61284117/react-router-404-page-always-showing-due-to-custom-route-component
 */

const Routes = () => (
  <>
    {Object.values(Routing).map((route, index) => (
      <Route key={index} route={route} />
    ))}
  </>
);

export const AppActionTypes = {
  LOGOUT: "LOGOUT",
  LOGIN: "LOGIN",
} as const;
type AppActionTypes = (typeof AppActionTypes)[keyof typeof AppActionTypes];

interface AppType {
  loginUserName: string | null;
}

const initialState: AppType = {
  loginUserName: null,
};

function reducer(state: AppType, action: IAction): AppType {
  switch (action.type) {
    case AppActionTypes.LOGIN:
      return {
        ...state,
        loginUserName: action.value.name,
      };
    case AppActionTypes.LOGOUT:
      return {
        ...state,
        loginUserName: null,
      };
    default:
      return { ...state };
  }
}

export const AppProvider = connect<AppType>(reducer, initialState);

function Login() {
  const dispatch = AppProvider.useDispatch();

  useFetch(
    useCallback(
      async (signal: CancelTokenSource) => {
        const result = await Api.get<AccountModel>(ApiUrl.Account());
        dispatch({ type: AppActionTypes.LOGIN, value: { name: result.data.userName } });
      },
      [dispatch]
    )
  );

  return <></>;
}

function App() {
  const classes = useStyles();

  const dispatch = AppProvider.useDispatch();
  const isLoggedIn = AppProvider.useGlobalState("loginUserName") !== null;

  const alertAdd = useAlertAdd();

  const location = useLocation();

  const isTenants = useMemo(() => [Routing.Top.path, Routing.TenantManagement.path].includes(location.pathname), [location]);

  const onError = useCallback(
    async (error: any) => {
      if (error.response !== undefined && error.response.status === 401) {
        alert("認証権限がありません。");
        dispatch({ type: AppActionTypes.LOGOUT, value: {} });
        msal.logoutRedirect();
      } else {
        if (error.message !== abortMessage) {
          alertAdd("error", error.message);
        }
      }
    },
    [alertAdd, dispatch]
  );

  useEffect(() => {
    Api.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        onError(error);
        throw error;
      }
    );
  }, [onError]);

  return (
    <div className={classes.root}>
      <AlertList />
      {isLoggedIn ? (
        <>
          <Header title="ユーザーメンテナンス画面" />
          <Menu />
          <main className={classes.content}>
            <Toolbar />
            <Container maxWidth={isTenants ? false : "lg"} className={classes.container}>
              <Switch>
                <Routes />
              </Switch>
            </Container>
          </main>
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}

function AppInContext() {
  return (
    <AlertProvider.Provider>
      <AppProvider.Provider>
        <MsalProvider instance={msal}>
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <>
              <CssBaseline />
              <BrowserRouter>
                <Switch>
                  <RouterRoute path="/UserInviteComplete">
                    <UserInviteComplete />
                  </RouterRoute>
                  <RouterRoute path="*">
                    <App />
                  </RouterRoute>
                </Switch>
              </BrowserRouter>
            </>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </AppProvider.Provider>
    </AlertProvider.Provider>
  );
}

export default React.memo(AppInContext);
