import React, { useCallback, useMemo, useState } from "react";
import { Checkbox, DialogTitle, FormControlLabel, FormHelperText, Grid, Theme, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Api, { ApiUrl } from "common/Api";
import { useInputManager } from "common/HandleUtility";
import { useGenericStyles } from "common/Styles";
import DigitsField from "common/components/DigitsField";
import { useAlertAdd } from "components/AlertList";
import TextField from "components/TextField";
import { ButtonEx } from "components/Wrapping";
import { useExecute } from "hooks/useFetch";
import { useMessageBox } from "hooks/useMessageBox";
import { Utility } from "utils/Utility";

const useStyles = makeStyles((theme: Theme) => ({
  inEdit: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
}));

interface Input {
  prefixUserId: string;
  prefixUserName: string;
  createNumber?: number;
  startIndex: number;
  password?: string;
  manager: boolean;
}

type Props = {
  onClose: (post: boolean) => void;
  tenantGuid: string;
};

export const UserBulkCreateForm = (props: Props) => {
  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();
  const classes = useStyles();
  const genericClasses = useGenericStyles();

  const [input, setInput] = useState<Input>({
    prefixUserId: "",
    prefixUserName: "",
    startIndex: 1,
    manager: false,
  });
  const inputManager = useInputManager(setInput);

  const isValid = useMemo(() => {
    return (
      input.prefixUserId !== "" &&
      input.prefixUserName !== "" &&
      input.createNumber != null &&
      input.createNumber > 0 &&
      input.startIndex != null &&
      input.startIndex > 0 &&
      input.password != null &&
      input.password.length >= 8
    );
  }, [input]);

  const [executePost, inProcess] = useExecute(
    useCallback(
      async (unmounted: { value: boolean }, param: { tenantGuid: string; input: Input }): Promise<void> => {
        try {
          const result = await Api.post(ApiUrl.UserBulkCreate(param.tenantGuid), param.input);

          const isErrorResponse = Utility.isErrorResonse(result.data);
          if (!isErrorResponse) {
            alertAdd("info", "ユーザーを登録しました");
          }

          if (unmounted.value) {
            return;
          }

          if (isErrorResponse) {
            if (result.data.code === 301) {
              await messagebox.error("ユーザ招待エラー", `${result.data.message}\n以上のユーザーは登録済みです`);
            }
          } else {
            props.onClose(true);
          }
        } catch (e: any) {
          if (e.isAxiosError && e.response && e.response.data) {
            if (e.response.status === 400 && e.response.data.code === 202) {
              await messagebox.error("ユーザー作成エラー", "パスワードの複雑さが要件を満たしていません");
            } else if (e.response.status === 409 && e.response.data.code === 201) {
              await messagebox.error("ユーザー作成エラー", "既に使用されているIDです");
            }
          }
        }
      },
      [messagebox, alertAdd]
    )
  );

  const handleRegister = useCallback(() => {
    executePost({ tenantGuid: props.tenantGuid, input });
  }, [executePost, props.tenantGuid, input]);

  return (
    <Dialog open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">ユーザー一括登録</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <DigitsField
              type="number"
              className={classes.inEdit}
              label="開始番号"
              onChange={inputManager.handleOnChangeNumber("startIndex")}
              value={input.startIndex}
              autoFocus
              inputProps={{
                max: 999,
                min: 1,
              }}
              max={999}
              min={1}
            />
          </Grid>
          <Grid item xs={6}>
            <DigitsField
              type="number"
              className={classes.inEdit}
              label="作成数"
              onChange={inputManager.handleOnChangeNumber("createNumber")}
              value={input.createNumber}
              inputProps={{
                max: 999,
                min: 1,
              }}
              max={999}
              min={1}
            />
          </Grid>
        </Grid>
        <TextField
          className={classes.inEdit}
          label="プレフィクスユーザーID"
          inputProps={{ maxLength: 60 }}
          onChange={inputManager.handleOnChange("prefixUserId")}
          value={input.prefixUserId}
        />
        <TextField
          className={classes.inEdit}
          label="プレフィクスユーザー名"
          inputProps={{ maxLength: 252 }}
          onChange={inputManager.handleOnChange("prefixUserName")}
          value={input.prefixUserName}
        />
        <TextField
          className={classes.inEdit}
          label="パスワード"
          type="password"
          inputProps={{ maxLength: 256 }}
          onChange={inputManager.handleOnChange("password")}
          value={input.password}
        />
        <FormHelperText>アルファベットの大文字、小文字、数字、記号の3つ以上を使用してください</FormHelperText>
        <FormControlLabel
          control={<Checkbox checked={input.manager} onChange={inputManager.handleOnChangeCheck("manager")} />}
          label="管理者"
        />
      </DialogContent>
      <DialogActions>
        <ButtonEx color="primary" variant="contained" onClick={handleRegister} disabled={!isValid || inProcess}>
          登録
        </ButtonEx>
        <ButtonEx variant="contained" onClick={() => props.onClose(false)}>
          閉じる
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
