import React, { useCallback, useRef, useState } from "react";
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { CancelTokenSource } from "axios";
import Api, { ApiUrl } from "common/Api";
import { TenantCategory, TenantClass, DisplayTenantCategories } from "common/Constant";
import { useGenericStyles } from "common/Styles";
import { useAlertAdd } from "components/AlertList";
import { LoadingMode, useLoadingElement } from "components/Loading";
import { Page, PageBody, PageHeader } from "components/Page";
import Select from "components/Select";
import { TenantForm } from "components/TenantManagement/TenantForm";
import { TenantTable } from "components/TenantManagement/TenantTable";
import { ButtonEx } from "components/Wrapping";
import { CallbackWithState, useExecute, useFetch } from "hooks/useFetch";
import { useMessageBox } from "hooks/useMessageBox";
import { TenantModel } from "models/TenantModel";

export const initTenant: () => TenantModel = () => ({
  category: TenantCategory.Partner,
  area: 0,
  parentGuid: null,
  childTenants: [],
  tenantClass: TenantClass.UnClass,
  relationTenants: [],
  displayCategory: DisplayTenantCategories[0].value,
});

const recursiveTenant = (tenants: TenantModel[], ret: (string | undefined)[]) => {
  if (tenants.length === 0) {
    return;
  }

  for (const value of tenants) {
    ret.push(value.guid);
  }

  return () =>
    recursiveTenant(
      tenants.reduce((sum: TenantModel[], i: TenantModel) => sum.concat(i.childTenants), []),
      ret
    );
};

const trampoline = (fn: any) => {
  return (...args: any[]) => {
    let result = fn(...args);
    while (typeof result === "function") {
      result = result();
    }
    return result;
  };
};

export const AllCategory = 0;

const getIgnoreTenantIds: (tenants: TenantModel[], ret: (string | undefined)[]) => void = trampoline(recursiveTenant);

export const TenantManagement = () => {
  const [tenantNodes, setTenantNodes] = useState<TenantModel[]>([]);
  const [tenantList, setTenantList] = useState<TenantModel[]>([]);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editTenant, setEditTenant] = useState<TenantModel>(initTenant);
  const openList = useRef<{ [key: string]: boolean }>({});
  const [condition, setCondition] = useState(AllCategory);

  const [editParentTenantList, setEditParentTenantList] = useState<TenantModel[]>();

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();
  const classes = useGenericStyles();

  const fetchResult = useFetch(
    useCallback(async (signal: CancelTokenSource): Promise<void> => {
      const url = ApiUrl.Tenant();
      const response = await Api.get<TenantModel[]>(url, { cancelToken: signal.token });

      if (!response.data) {
        return;
      }

      response.data.forEach((value) => {
        if (value.tenantClass === TenantClass.UnClass) {
          value.displayCategory = -value.category;
        } else {
          value.displayCategory = value.tenantClass;
        }
      });

      setTenantNodes(() => {
        const ret: TenantModel[] = [];

        for (const i of response.data) {
          i.childTenants = response.data.filter((value) => value.parentGuid === i.guid);

          if (i.parentGuid == null) ret.push(i);
          else if (!response.data.some((value) => i.parentGuid === value.guid)) ret.push(i);
        }

        return ret;
      });
      setTenantList(response.data);
    }, [])
  );

  const detailLoadingElement = useLoadingElement(classes.loadingPageBody, LoadingMode.Circular, fetchResult);
  const reload = fetchResult.reload;

  const handleOnChangeCondition = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
      setCondition(event.target.value as number);
    },
    []
  );

  const handleAddChildTenant = useCallback((parentTenant: TenantModel) => {
    setEditTenant({ ...initTenant(), parentGuid: parentTenant.guid ?? null });
    setEditParentTenantList([parentTenant]);

    setOpenForm(true);
  }, []);

  const handleAddTenant = useCallback(() => {
    setEditTenant(initTenant);
    setEditParentTenantList(tenantList);

    setOpenForm(true);
  }, [tenantList]);

  const handleEditTenant = useCallback(
    (editTenant: TenantModel) => {
      setEditTenant(editTenant);

      const ignoreIds = [editTenant.guid];
      getIgnoreTenantIds(editTenant.childTenants, ignoreIds);
      setEditParentTenantList(tenantList.filter((tenant) => !ignoreIds.includes(tenant.guid)));

      setOpenForm(true);
    },
    [tenantNodes, tenantList]
  );

  const handleCloseForm = useCallback(
    (result: any) => {
      setOpenForm(false);

      if (result) {
        fetchResult.reload();
      }
    },
    [fetchResult]
  );

  const deleteTenant = useCallback(
    async (unmounted: { value: boolean }, param: { tenantGuid: string }) => {
      await Api.delete<TenantModel>(ApiUrl.Tenant(param.tenantGuid));

      alertAdd("info", "テナントを削除しました");

      if (unmounted.value) {
        return;
      }

      reload();
    },
    [alertAdd, reload]
  );

  const [executeDeleteTenant, deleteTenantInProcess] = useExecute(deleteTenant);

  const handleDeleteTenant = useCallback(
    async (tenant: TenantModel): Promise<void> => {
      if (await messagebox.confirm("削除確認", "テナントを削除してよろしいですか？")) {
        if (tenant.guid != null) {
          executeDeleteTenant({ tenantGuid: tenant.guid });
        }
      }
    },
    [executeDeleteTenant, messagebox]
  );

  return (
    <Page>
      <PageHeader
        title="テナント管理"
        condition={
          <FormControl style={{ width: 210 }}>
            <InputLabel>分類</InputLabel>
            <Select onChange={handleOnChangeCondition} value={condition}>
              <MenuItem key={0} value={AllCategory}>
                すべて
              </MenuItem>
              {DisplayTenantCategories.map((value) => (
                <MenuItem key={value.value} value={value.value}>
                  {value.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      >
        <ButtonEx className={classes.marginTop} variant="contained" color="primary" onClick={handleAddTenant}>
          追加
        </ButtonEx>
      </PageHeader>
      <PageBody>
        {detailLoadingElement ?? (
          <>
            <TenantTable
              tenants={tenantNodes}
              onAddChildTenant={CallbackWithState(handleAddChildTenant)}
              onEditTenant={CallbackWithState(handleEditTenant)}
              onDeleteTenant={CallbackWithState(handleDeleteTenant, deleteTenantInProcess)}
              openList={openList}
              condition={condition}
            />
            <TenantForm
              open={openForm}
              onClose={handleCloseForm}
              tenant={editTenant}
              parentTenants={editParentTenantList}
              tenants={tenantList}
            />
          </>
        )}
      </PageBody>
    </Page>
  );
};
