import React, { MutableRefObject, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import { MessageBox, MessageType } from "components/MessageBox";

type MessageBoxController = {
  info: (title: string, message: string) => Promise<boolean>;
  confirm: (title: string, message: string) => Promise<boolean>;
  warning: (title: string, message: string) => Promise<boolean>;
  error: (title: string, message: string) => Promise<boolean>;
};

const getParentElement = (): Element => {
  return document.querySelector("body")!;
};

export const useMessageBox = (ref?: MutableRefObject<Element | undefined>): MessageBoxController => {
  const showDialog = useCallback(
    (type: MessageType, title: string, message: string): Promise<boolean> => {
      const dialogContainer = document.createElement("div");
      const parentElement = getParentElement();
      parentElement.appendChild(dialogContainer);

      return new Promise<boolean>((resolve, reject) => {
        const handleClose = (result: boolean): void => {
          parentElement.removeChild(dialogContainer);
          ReactDOM.unmountComponentAtNode(dialogContainer);
          resolve(result);
        };
        ReactDOM.render(
          <MessageBox isOpen={true} type={type} title={title} message={message} onClose={handleClose} />,
          dialogContainer
        );
      });
    },
    []
  );

  return useMemo(
    () => {
      return {
        info: (title: string, message: string) => showDialog("info", title, message),
        confirm: (title: string, message: string) => showDialog("confirm", title, message),
        warning: (title: string, message: string) => showDialog("warning", title, message),
        error: (title: string, message: string) => showDialog("error", title, message),
      };
    },
    [showDialog]
  );
};
