import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Api, { ApiUrl } from "common/Api";
import { UserModel } from "models/UserModel";
import { UserTable } from "components/UserManagement/UserTable";
import { UserForm } from "components/UserManagement/UserForm";
import { useMessageBox } from "hooks/useMessageBox";
import { PlanModel } from "models/PlanModel";
import { PlanAssignForm } from "components/UserManagement/PlanAssignForm";
import { useTenant } from "hooks/useTenant";
import { UserCreateForm } from "components/UserManagement/UserCreateForm";
import { formatPageTitle, Page, PageBody, PageHeader } from "components/Page";
import { ButtonEx } from "components/Wrapping";
import { useFetch, useExecute, CallbackWithState } from "hooks/useFetch";
import { LoadingMode, useLoadingElement } from "components/Loading";
import { useGenericStyles } from "common/Styles";
import { useAlertAdd } from "components/AlertList";
import { TenantLicenseModel } from "models/TenantLicenseModel";
import { UserBulkCreateForm } from "components/UserManagement/UserBulkCreateForm";
export const UserManagement = () => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [licensedPlans, setLicensedPlans] = useState<PlanModel[]>([]);
  const [openUserForm, setOpenUserForm] = useState<boolean>(false);
  const [openUserCreateForm, setOpenUserCreateForm] = useState<boolean>(false);
  const [openPlanForm, setOpenPlanForm] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<UserModel>();
  const [editUnassignPlans, setEditUnassignPlans] = useState<PlanModel[]>([]);
  const [showBulkCreateUser, setShowBulkCreateUser] = useState(false);

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();
  const classes = useGenericStyles();

  const { tenantGuid } = useParams<{ tenantGuid: string }>();
  const tenant = useTenant(tenantGuid);

  const load = useCallback(async (): Promise<void> => {
    const urlUsers = ApiUrl.User(tenantGuid);
    const responseUsers = await Api.get(urlUsers);
    setUsers(responseUsers.data);

    const urlTenantLicense = ApiUrl.TenantLicense(tenantGuid);
    const responsedTenantLicense = await Api.get<TenantLicenseModel[]>(urlTenantLicense);
    var licensedPlans: PlanModel[] = responsedTenantLicense.data.map((license) => {
      return {
        guid: license.planGuid,
        name: license.planName,
        services: [],
      };
    });
    setLicensedPlans(licensedPlans);
  }, [tenantGuid]);

  const fetchResult = useFetch(load);
  const loadingElement = useLoadingElement(classes.loadingPageBody, LoadingMode.Circular, fetchResult);
  const reload = fetchResult.reload;

  const handleCreateUser = useCallback(() => {
    setOpenUserCreateForm(true);
  }, []);

  const handleCloseUserCreateForm = useCallback(
    (result: any) => {
      setOpenUserCreateForm(false);

      if (result) {
        fetchResult.reload();
      }
    },
    [fetchResult]
  );

  const handleInviteUser = useCallback(() => {
    setEditUser(undefined);
    setOpenUserForm(true);
  }, []);

  const handleOnClickBulkCreateUser = useCallback(() => {
    setShowBulkCreateUser(true);
  }, []);

  const handleEditUser = useCallback((user: UserModel) => {
    setEditUser(user);
    setOpenUserForm(true);
  }, []);

  const handleCloseUserForm = useCallback(
    (result: any) => {
      setOpenUserForm(false);

      if (result) {
        fetchResult.reload();
      }
    },
    [fetchResult]
  );

  const handleOnCloseBulkForm = useCallback(
    (post: boolean) => {
      setShowBulkCreateUser(false);

      if (post) {
        fetchResult.reload();
      }
    },
    [fetchResult]
  );

  const deleteUser = useCallback(
    async (unmounted: { value: boolean }, param: { tenantGuid: string; userGuid: string }) => {
      await Api.delete<UserModel>(ApiUrl.User(param.tenantGuid, param.userGuid));

      alertAdd("info", "ユーザーを削除しました");

      if (unmounted.value) {
        return;
      }

      reload();
    },
    [alertAdd, reload]
  );

  const [executeDeleteUser, deleteUserInProcess] = useExecute(deleteUser);

  const handleDeleteUser = useCallback(
    async (user: UserModel): Promise<void> => {
      if (await messagebox.confirm("削除確認", "ユーザを削除してよろしいですか？")) {
        executeDeleteUser({ tenantGuid: tenantGuid, userGuid: user.guid });
      }
    },
    [executeDeleteUser, messagebox, tenantGuid]
  );

  const unassignedPlans = useCallback(
    (user: UserModel): PlanModel[] => {
      const assignedPlans = user.plans;
      return licensedPlans.filter((plan) => !assignedPlans.some((assignedPlan) => assignedPlan.guid === plan.guid));
    },
    [licensedPlans]
  );

  const handleAssignPlan = useCallback(
    (user: UserModel) => {
      setEditUser(user);
      setEditUnassignPlans(unassignedPlans(user));
      setOpenPlanForm(true);
    },
    [unassignedPlans]
  );

  const handleClosePlanForm = useCallback(
    (result: any) => {
      setOpenPlanForm(false);

      if (result) {
        fetchResult.reload();
      }
    },
    [fetchResult]
  );

  const unassignPlan = useCallback(
    async (unmounted: { value: boolean }, param: { tenantGuid: string; userGuid: string; planGuid: string }) => {
      await Api.delete(ApiUrl.UserLicense(param.tenantGuid, param.userGuid, param.planGuid));

      alertAdd("info", "プランの割り当てを解除しました");

      if (unmounted.value) {
        return;
      }

      reload();
    },
    [alertAdd, reload]
  );

  const [executeUnassignPlan, unassignPlanInProcess] = useExecute(unassignPlan);

  const handleUnassignPlan = useCallback(
    async (user: UserModel, plan: PlanModel): Promise<void> => {
      if (await messagebox.confirm("削除確認", "プランの割り当てを解除してよろしいですか？")) {
        executeUnassignPlan({
          tenantGuid: tenantGuid,
          userGuid: user.guid,
          planGuid: plan.guid,
        });
      }
    },
    [executeUnassignPlan, messagebox, tenantGuid]
  );

  const explanation = (): string => {
    return `${tenant?.name}のユーザーの管理とライセンス割り当てを行う画面です。
メールアドレスをお持ちでない方は作成ボタンから、メールアドレスをお持ちの方は招待ボタンからユーザーの追加を行ってください。
`;
  };

  return (
    <Page>
      <PageHeader title={formatPageTitle("ユーザ管理", tenant?.name)} explanation={explanation()}>
        <ButtonEx variant="contained" color="primary" onClick={handleCreateUser}>
          作成
        </ButtonEx>
        <ButtonEx variant="contained" color="primary" onClick={handleOnClickBulkCreateUser}>
          一括作成
        </ButtonEx>
        <ButtonEx variant="contained" color="primary" onClick={handleInviteUser}>
          招待
        </ButtonEx>
      </PageHeader>
      <PageBody>
        {loadingElement ?? (
          <>
            <UserTable
              users={users}
              onEditUser={CallbackWithState(handleEditUser)}
              onDeleteUser={CallbackWithState(handleDeleteUser, deleteUserInProcess)}
              onAssignPlan={CallbackWithState(handleAssignPlan)}
              onUnassignPlan={CallbackWithState(handleUnassignPlan, unassignPlanInProcess)}
            />
            <UserCreateForm open={openUserCreateForm} onClose={handleCloseUserCreateForm} tenantGuid={tenantGuid} />
            <UserForm open={openUserForm} onClose={handleCloseUserForm} tenantGuid={tenantGuid} user={editUser} />
            <PlanAssignForm
              open={openPlanForm}
              onClose={handleClosePlanForm}
              tenantGuid={tenantGuid}
              userGuid={editUser?.guid ?? ""}
              plans={editUnassignPlans}
            />
            {showBulkCreateUser && <UserBulkCreateForm onClose={handleOnCloseBulkForm} tenantGuid={tenantGuid} />}
          </>
        )}
      </PageBody>
    </Page>
  );
};
