export class Utility {
  /**
   * メッセージのフォーマット
   * @example
   * let message = Utility.format("{0} world! {1} {0}!! {2}", "hello", 2);
   * console.log(message); // hello world! 2 hello!! undefined
   * @param format フォーマット文字列(.net like)
   * @param parameters 可変部分のパラメータ
   * @returns フォーマットしたメッセージ
   */
  static format = (format: string, ...parameters: any): string => {
    return format.replace(/{(\d+)}/g, (match, index) => String(parameters[index]));
  };

  static toNullWhenEmpty = (value: string | null): string | null => {
    return value ? value : null;
  };

  static isErrorResonse = (obj: Object): boolean => {
    return obj !== null && obj !== undefined && "isError" in obj;
  };
}
