import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AreaAssignModel } from 'models/AreaAssignModel';
import { Checkbox } from '@material-ui/core';

type Props = {
  areaAssigns: AreaAssignModel[];
  onCheckRow: (index: number) => void;
  onCheckAll: (checked: boolean) => void;
};

enum TableCheckState {
  // チェックなし
  NoCheck,
  // 混在
  Indeterminate,
  // 全チェック
  AllCheck,
}

const getTableCheckState = (areaAssigns: AreaAssignModel[]): TableCheckState => {
  var firstState = areaAssigns[0].isAssigned;
  if (areaAssigns.some((value) => value.isAssigned !== firstState)) {
    return TableCheckState.Indeterminate;
  }
  return firstState ? TableCheckState.AllCheck : TableCheckState.NoCheck;
};

export const AreaAssignmentTable = (props: Props) => {
  const [tableCheckState, setTableCheckState] = useState<TableCheckState>(TableCheckState.NoCheck);

  useEffect(() => {
    if (props.areaAssigns && props.areaAssigns.length > 0) {
      setTableCheckState(getTableCheckState(props.areaAssigns));
    }
  }, [props.areaAssigns]);

  const handleCheckHeader = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onCheckAll(event.target.checked);
    },
    [props]
  );

  const handleCheckRow = useCallback(
    (index: number) => {
      props.onCheckRow(index);
    },
    [props]
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={tableCheckState === TableCheckState.Indeterminate}
                checked={tableCheckState === TableCheckState.AllCheck}
                onChange={handleCheckHeader}
              />
            </TableCell>
            <TableCell>エリア名称</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.areaAssigns.map((areaAssign, index) => (
            <TableRow key={index}>
            <TableCell padding="checkbox">
            <Checkbox
              checked={areaAssign.isAssigned}
              onChange={() => handleCheckRow(index)}
            />
            </TableCell>
            <TableCell component="th" scope="row">
              {areaAssign.areaName}
            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
