import React, { useState, useEffect, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { UserModel } from "models/UserModel";
import Api, { ApiUrl } from "common/Api";
import { DialogTitle, Grid, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { useMessageBox } from "hooks/useMessageBox";
import { ButtonEx } from "components/Wrapping";
import { useAlertAdd } from "components/AlertList";
import { useExecute } from "hooks/useFetch";
import { Utility } from "utils/Utility";

type Props = {
  open: boolean;
  onClose: (result?: any) => void;
  tenantGuid: string;
  user?: UserModel;
};

export const UserForm = (props: Props) => {
  const [userId, setUserId] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [manager, setManager] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();

  const isEditMode = props.user != null;
  const onCloseCallback = props.onClose;

  const onShowForm = useCallback(() => {
    if (isEditMode) {
      setUserId(props.user!.userId);
      setUserName(props.user!.name);
      setManager(props.user!.manager);
    } else {
      setUserId("");
      setUserName("");
      setManager(false);
    }
  }, [isEditMode, props.user]);

  useEffect(() => {
    if (!props.open) {
      return;
    }

    onShowForm();
  }, [onShowForm, props.open]);

  useEffect(() => {
    const valid = userId !== "" && userName !== "";
    setIsValid(valid);
  }, [isEditMode, userId, userName]);

  const register = useCallback(
    async (
      unmounted: { value: boolean },
      param: { tenantGuid: string; userId: string; name: string; manager: boolean }
    ): Promise<void> => {
      try {
        const result = await Api.post(ApiUrl.UserInvite(param.tenantGuid), {
          userId: param.userId,
          name: param.name,
          manager: param.manager,
        });

        const isErrorResponse = Utility.isErrorResonse(result.data);
        if (!isErrorResponse) {
          alertAdd("info", "ユーザーを招待しました");
        }

        if (unmounted.value) {
          return;
        }

        if (isErrorResponse) {
          if (result.data.code === 301) {
            await messagebox.error("ユーザ招待エラー", `${param.userId} は招待済みです`);
          }
        } else {
          onCloseCallback(result);
        }
      } catch (e: any) {
        if (e.isAxiosError && e.response && e.response.data) {
          if (e.response.status === 409 && e.response.data.code === 203) {
            await messagebox.error("ユーザー招待エラー", "招待できませんでした。IDを確認してください。");
          }
        }
      }
    },
    [alertAdd, messagebox, onCloseCallback]
  );

  const [executeRegister, registerInProcess] = useExecute(register);

  const update = useCallback(
    async (unmounted: { value: boolean }, param: { tenantGuid: string; userGuid: string; name: string }): Promise<void> => {
      const result = await Api.put<UserModel>(ApiUrl.User(param.tenantGuid, param.userGuid), {
        name: param.name,
      });

      alertAdd("info", "ユーザーを更新しました");

      if (unmounted.value) {
        return;
      }

      onCloseCallback(result);
    },
    [alertAdd, onCloseCallback]
  );

  const [executeUpdate, updateInProcess] = useExecute(update);

  const handleRegister = useCallback(() => {
    if (isEditMode) {
      executeUpdate({
        tenantGuid: props.tenantGuid,
        userGuid: props.user!.guid,
        name: userName,
      });
    } else {
      executeRegister({
        tenantGuid: props.tenantGuid,
        userId: userId,
        name: userName,
        manager: manager,
      });
    }
  }, [executeRegister, executeUpdate, isEditMode, props.tenantGuid, props.user, userId, userName, manager]);

  return (
    <Dialog open={props.open} maxWidth="xs">
      <DialogTitle id="form-dialog-title">{isEditMode ? "ユーザー編集" : "ユーザー登録"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id="id"
              label="ユーザーID"
              defaultValue={userId}
              inputProps={{ maxLength: 256 }}
              onChange={(e) => setUserId(e.target.value)}
              autoFocus
              disabled={isEditMode}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="ユーザー名"
              defaultValue={userName}
              inputProps={{ maxLength: 256 }}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={manager} onChange={(e) => setManager(e.target.checked)} disabled={isEditMode} />}
              label="管理者"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonEx
          color="primary"
          variant="contained"
          onClick={handleRegister}
          disabled={!isValid || registerInProcess || updateInProcess}
        >
          登録
        </ButtonEx>
        <ButtonEx variant="contained" onClick={() => props.onClose()}>
          閉じる
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
