import { PublicClientApplication, Configuration } from "@azure/msal-browser";
import { UserAgentApplication, Logger, LogLevel } from "msal";

export const GoodManagementUserAgentApplication = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_UAA_CLIENT_ID!,
    authority: process.env.REACT_APP_UAA_AUTHORITY!,
    redirectUri: process.env.REACT_APP_UAA_REDIRECT_URI!,
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    navigateFrameWait: 500,
    logger: new Logger(
      (logLevel, message) => {
        console.log(message);
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: true,
      }
    ),
    telemetry: {
      applicationName: "react-sample-app",
      applicationVersion: "1.0.0",
      telemetryEmitter: (events) => {
        console.log("Telemetry Events:", events);
      },
    },
  },
});

export const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_UAA_CLIENT_ID!,
    authority: process.env.REACT_APP_UAA_AUTHORITY!,
    redirectUri: process.env.REACT_APP_UAA_REDIRECT_URI!,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msal = new PublicClientApplication(configuration);
