import React, { useMemo } from "react";
import { IconButton, Theme, makeStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Add as IconAddChild, Delete as IconDelete, Edit as IconEdit } from "@material-ui/icons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
import { DisplayTenantCategories } from "common/Constant";
import { Routing } from "common/Routing";
import { LinkTo } from "components/Navigate";
import { ButtonEx, IconButtonEx } from "components/Wrapping";
import { CallbackWithState } from "hooks/useFetch";
import { TenantModel } from "models/TenantModel";

const useStyles = makeStyles((theme: Theme) => ({
  managementOperationCell: {
    "& button": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    "& a": {
      textDecoration: "none",
    },
  },
  rotateAnimation: {
    transition: "transform 0.2s ease",
  },
  rotate: {
    transform: "rotate(90deg)",
  },
}));

export type Events = {
  onAddChildTenant: CallbackWithState<(parentTenant: TenantModel) => void>;
  onEditTenant: CallbackWithState<(tenant: TenantModel) => void>;
  onDeleteTenant: CallbackWithState<(tenant: TenantModel) => void>;
};

type Props = {
  tenant: TenantModel;
  hierarchy: number;
  show: boolean;
  openChild: boolean;
  toggleOpen: () => void;
  hiddenToggleButton: boolean;
};

export const TenantTableRow = (props: Props & Events) => {
  const classes = useStyles();
  const { tenant } = props;

  const category = useMemo(
    () => DisplayTenantCategories.find((value) => value.value === tenant.displayCategory)?.text ?? "",
    [props.tenant.tenantClass]
  );

  return (
    <TableRow style={{ display: props.show ? "" : "none" }}>
      <TableCell component="th" scope="row">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: `calc(15px * ${props.hierarchy})`, height: 26 }} />
          {tenant.childTenants.length === 0 || props.hiddenToggleButton ? (
            <div style={{ width: 26, height: 26 }} />
          ) : (
            <IconButton size="small" style={{ marginBottom: 1 }} onClick={props.toggleOpen}>
              <ArrowForwardIosIcon
                className={clsx(classes.rotateAnimation, props.openChild && classes.rotate)}
                fontSize="small"
              />
            </IconButton>
          )}
          {props.tenant.name ?? ""}
        </div>
      </TableCell>
      <TableCell>{category}</TableCell>
      <TableCell align="right">
        <IconButtonEx
          color="primary"
          title="子テナント追加"
          aria-label="子テナント追加"
          onClick={() => props.onAddChildTenant.callback(tenant)}
          disabled={props.onAddChildTenant.inProcess}
        >
          <IconAddChild />
        </IconButtonEx>
        <IconButtonEx
          color="primary"
          title="編集"
          aria-label="編集"
          onClick={() => props.onEditTenant.callback(tenant)}
          disabled={props.onEditTenant.inProcess}
        >
          <IconEdit />
        </IconButtonEx>
        <IconButtonEx
          color="secondary"
          title="削除"
          aria-label="削除"
          onClick={() => props.onDeleteTenant.callback(tenant)}
          disabled={props.onDeleteTenant.inProcess}
        >
          <IconDelete />
        </IconButtonEx>
      </TableCell>
      <TableCell align="left" className={classes.managementOperationCell}>
        {tenant.guid != null && (
          <>
            <LinkTo route={Routing.UserManagement} pathParameters={{ tenantGuid: tenant.guid }}>
              <ButtonEx variant="contained" color="default">
                ユーザ
              </ButtonEx>
            </LinkTo>
            <LinkTo route={Routing.LicenseManagement} pathParameters={{ tenantGuid: tenant.guid }}>
              <ButtonEx variant="contained" color="default">
                ライセンス
              </ButtonEx>
            </LinkTo>
            <LinkTo route={Routing.AreaAssignment} pathParameters={{ tenantGuid: tenant.guid }}>
              <ButtonEx variant="contained" color="default">
                エリア
              </ButtonEx>
            </LinkTo>
            <LinkTo route={Routing.ServiceManagement} pathParameters={{ tenantGuid: tenant.guid }}>
              <ButtonEx variant="contained" color="default">
                サービス
              </ButtonEx>
            </LinkTo>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
