import React, { useCallback, useEffect, useMemo, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TenantModel } from "models/TenantModel";
import { AllCategory } from "pages/TenantManagement";
import { Events as TableRowEvents, TenantTableRow } from "./TenantTableRow";

const Recursive = React.memo(
  (
    props: Props & {
      hierarchy: number;
      show: boolean;
      openList: React.MutableRefObject<{ [key: string]: boolean }>;
      condition: number;
    }
  ) => {
    const [open, setOpen] = useState<boolean[]>([]);
    const isAllCondition = props.condition === AllCategory;

    const isValid = useMemo(() => {
      if (isAllCondition) {
        return Array(props.tenants.length).fill(true);
      }

      return props.tenants.map((value) => value.displayCategory === props.condition);
    }, [props.tenants, props.condition, isAllCondition]);

    useEffect(() => {
      setOpen(() => {
        const ret: boolean[] = [];

        for (const i of props.tenants) {
          if (props.openList.current[i.guid!] === undefined) {
            props.openList.current[i.guid!] = false;
          }
          ret.push(props.openList.current[i.guid!]);
        }

        return ret;
      });
    }, [props.tenants]);

    const handleOnToggleOpen = useCallback(
      (index: number, guid: string) => () =>
        setOpen((values) => {
          values[index] = !values[index];
          props.openList.current[guid] = values[index];
          return [...values];
        }),
      []
    );

    return (
      <>
        {props.tenants.map((tenant, index) => (
          <React.Fragment key={`fragment-${tenant.guid}`}>
            <TenantTableRow
              key={tenant.guid}
              tenant={tenant}
              hierarchy={props.hierarchy}
              onAddChildTenant={props.onAddChildTenant}
              onEditTenant={props.onEditTenant}
              onDeleteTenant={props.onDeleteTenant}
              show={isAllCondition ? props.show : isValid[index]}
              openChild={isAllCondition ? open[index] : true}
              toggleOpen={handleOnToggleOpen(index, tenant.guid!)}
              hiddenToggleButton={!isAllCondition}
            />
            <Recursive
              {...props}
              tenants={tenant.childTenants}
              hierarchy={isAllCondition ? props.hierarchy + 1 : 0}
              show={props.show && open[index]}
              openList={props.openList}
            />
          </React.Fragment>
        ))}
      </>
    );
  }
);

type Props = {
  tenants: TenantModel[];
  openList: React.MutableRefObject<{ [key: string]: boolean }>;
  condition: number;
} & TableRowEvents;

export const TenantTable = (props: Props) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>テナント名称</TableCell>
            <TableCell style={{ width: 200 }}>分類</TableCell>
            <TableCell style={{ width: 185 }}>アクション</TableCell>
            <TableCell style={{ width: 440 }}>管理</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Recursive key={"root"} {...props} hierarchy={0} show={true} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
