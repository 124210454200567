import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Api, { ApiUrl } from "common/Api";
import { ServiceModel } from "models/ServiceModel";
import { ServiceTable } from "components/ServiceManagement/ServiceTable";
import { ServiceForm } from "components/ServiceManagement/ServiceForm";
import { useMessageBox } from "hooks/useMessageBox";
import { useTenant } from "hooks/useTenant";
import { formatPageTitle, Page, PageBody, PageHeader } from "components/Page";
import { ButtonEx } from "components/Wrapping";
import { CallbackWithState, useExecute, useFetch } from "hooks/useFetch";
import { LoadingMode, useLoadingElement } from "components/Loading";
import { useGenericStyles } from "common/Styles";
import { useAlertAdd } from "components/AlertList";

export const ServiceManagement = () => {
  const [services, setServices] = useState<ServiceModel[]>([]);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editService, setEditService] = useState<ServiceModel>();

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();
  const classes = useGenericStyles();

  const { tenantGuid } = useParams<{ tenantGuid: string }>();
  const tenant = useTenant(tenantGuid);

  const load = useCallback(async (): Promise<void> => {
    const url = ApiUrl.Service(tenantGuid);
    const response = await Api.get(url);
    setServices(response.data);
  }, [tenantGuid]);

  const fetchResult = useFetch(load);
  const loadingElement = useLoadingElement(classes.loadingPageBody, LoadingMode.Circular, fetchResult);
  const reload = fetchResult.reload;

  const handleAddService = useCallback(() => {
    setEditService(undefined);
    setOpenForm(true);
  }, []);

  const handleEditService = useCallback((service: ServiceModel) => {
    setEditService(service);
    setOpenForm(true);
  }, []);

  const handleCloseForm = useCallback(
    (result: any) => {
      setOpenForm(false);

      if (result) {
        fetchResult.reload();
      }
    },
    [fetchResult]
  );

  const deleteService = useCallback(
    async (unmounted: { value: boolean }, param: { tenantGuid: string; service: ServiceModel }) => {
      await Api.delete<ServiceModel>(ApiUrl.Service(param.tenantGuid, param.service.guid));

      alertAdd("info", "サービスを削除しました");

      if (unmounted.value) {
        return;
      }

      reload();
    },
    [alertAdd, reload]
  );

  const [executeDeleteService, deleteServiceInProcess] = useExecute(deleteService);

  const handleDeleteService = useCallback(
    async (service: ServiceModel): Promise<void> => {
      if (await messagebox.confirm("削除確認", "サービスを削除してよろしいですか？")) {
        executeDeleteService({ tenantGuid: tenantGuid, service: service });
      }
    },
    [executeDeleteService, messagebox, tenantGuid]
  );

  return (
    <Page>
      <PageHeader
        title={formatPageTitle("サービス管理", tenant?.name)}
        explanation={`${tenant?.name}が提供するサービスを管理する画面です。`}
      >
        <ButtonEx variant="contained" color="primary" onClick={handleAddService}>
          追加
        </ButtonEx>
      </PageHeader>
      <PageBody>
        {loadingElement ?? (
          <>
            <ServiceTable
              services={services}
              tenantGuid={tenant?.guid ?? ""}
              onEditService={CallbackWithState(handleEditService)}
              onDeleteService={CallbackWithState(handleDeleteService, deleteServiceInProcess)}
            />
            <ServiceForm open={openForm} onClose={handleCloseForm} tenantGuid={tenantGuid} service={editService} />
          </>
        )}
      </PageBody>
    </Page>
  );
};
