import React, { useMemo, useCallback, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Grid, Box, IconButton, TableCell, Typography, Menu, FormControlLabel, Checkbox } from "@material-ui/core";
import { AutoSizer, Column, Table, TableHeaderProps } from "react-virtualized";
import { Edit, Delete, Done, CancelOutlined, Info, AddBox } from "@material-ui/icons";
import { sortWithSet } from "common/utility/GenericsInterface";

export const InputState = {
  None: 0,
  Adding: 1,
  Modifying: 2,
} as const;
export type InputState = (typeof InputState)[keyof typeof InputState];

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  table: {},
  tableRowHeader: {
    cursor: "pointer", // 行選択時のカーソルの形状
  },
  tableRow: {
    cursor: "pointer", // 行選択時のカーソルの形状
    width: "100% !important", // body部分はリサイズに行幅が更新されないので100%で上書き
    paddingRight: "0 !important", // スクロールバー分がパディングされてしまうが100%の場合はスクロール分を含まないので0で上書き
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200], // 行ホバー時の行の背景色
    },
  },
  tableCell: {
    flex: 1, // セル幅の内訳
    width: "100%",
  },
  inCell: {
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  noClick: {
    cursor: "initial", // イベントが設定されていない状態でセル上にホバーした際のマウスの形状
  },
  width100percent: {
    width: "100%",
  },
  menu: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
}));

export enum EditType {
  EditButton,
  ReferenceButton,
  DeleteButton,
  AllowEdit,
}

export interface ColumnData {
  dataKey?: string;
  label: string;
  width: number;
  fit?: boolean; // しわ寄せ列の設定 1箇所のみ設定
  headerAlign?: "inherit" | "left" | "center" | "right" | "justify";
  bodyAlign?: "inherit" | "left" | "center" | "right" | "justify";
  editType?: EditType;
  component?: (data: any, rowIndex: number) => JSX.Element;
  headerComponent?: (columnData: ColumnData) => JSX.Element;
  convert?: (data: any, rowData: any) => string;
  rendererInHeader?: (label: React.ReactNode, columnData: ColumnData, columnIndex: number) => JSX.Element;
  rendererInCell?: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => JSX.Element;
  componentWhenEditing?: (rowIndex: number) => JSX.Element;
  sort?: (direction: boolean) => void;
  disabled?: boolean;
  visible?: boolean;
  widthType?: "fix";
}

interface Row {
  index: number;
}

interface Props {
  columns: ColumnData[];
  headerHeight: number;
  hover?: boolean;
  tableHeight?: number;
  rowHeight?: number;
  values: any[];
  setValues?: React.Dispatch<React.SetStateAction<any[]>>;
  onClickAddDirectInput?: () => void;
  onClickAdd?: () => void;
  onClickDelete: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => void;
  onClickSave?: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number, args: { cancel: boolean }) => void;
  onClickEdit?: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => void;
  onClickReference: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => void;
  onClickCancel?: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => void;
  onClickCell?: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => void;
  setEditing?: React.Dispatch<React.SetStateAction<InputState>>;
  onUnmounted?: (inputState: InputState) => void;
  onContextMenuHeader?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChangeHeaderVisible?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    filteredColumns: ColumnData[],
    index: number
  ) => void;
  onCloseContextMenu?: (filteredColumns: ColumnData[]) => void;
  headerContext?: boolean;
}

const MuiVirtualizedTable = (props: Props) => {
  const classes = useStyles();

  const [popoverPosition, setPopoverPosition] = useState<{ top: number; left: number } | undefined>(undefined);

  const [sortDirection, setSortDirection] = useState<number>(-1);

  const [editingRowIndex, setEditingRowIndex] = useState<number>(-1);

  const validColumns = useMemo(() => props.columns.filter((value) => value.visible == null || value.visible), [props.columns]);

  const filteredColumns = useMemo(() => props.columns.filter((value) => value.visible != null), [props.columns]);

  // 列の指定幅の合計
  const totalWidth: number = useMemo((): number => {
    let total: number = 0;
    validColumns.forEach((column) => {
      total += column.width;
    });

    return total;
  }, [validColumns]);

  // Table.rowClassName
  const getRowClassName = useCallback(
    ({ index }: Row) => {
      return clsx(
        {
          [classes.tableRow]: index !== -1,
        },
        {
          [classes.tableRowHeader]: index === -1,
        },
        classes.flexContainer,
        {
          [classes.tableRowHover]: index !== -1 && (props.hover ?? false),
        }
      );
    },
    [classes.flexContainer, classes.tableRow, classes.tableRowHeader, classes.tableRowHover, props.hover]
  );

  const handleOnClickAdd = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      props.onClickAdd?.();
      event.stopPropagation();
    },
    [props.onClickAdd]
  );

  const handleOnClickAddDirectInput = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      props.onClickAddDirectInput?.();

      setEditingRowIndex(props.values.length);
      props.setEditing?.(InputState.Adding);
      event.stopPropagation();
    },
    [props.onClickAddDirectInput, props.setEditing, props.values.length]
  );

  const handleOnClickSave = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const args = { cancel: false };
        props.onClickSave?.(data, columnData, rowIndex, columnIndex, args);

        if (!args.cancel) {
          setEditingRowIndex(-1);
          props.setEditing?.(InputState.None);
        }

        event.stopPropagation();
      },
    [props.onClickSave, props.setEditing]
  );

  const handleOnClickEdit = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onClickEdit?.(data, columnData, rowIndex, columnIndex);

        setEditingRowIndex(rowIndex);
        props.setEditing?.(InputState.Modifying);
        event.stopPropagation();
      },
    [props.onClickEdit, props.setEditing]
  );

  const handleOnClickCancel = useCallback(
    (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onClickCancel?.(data, columnData, rowIndex, columnIndex);

        setEditingRowIndex(-1);
        props.setEditing?.(InputState.None);
        event.stopPropagation();
      },
    [props.onClickCancel, props.setEditing]
  );

  const handleOnClickIcon = useCallback(
    (
        onClick: (data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => void,
        data: any,
        columnData: ColumnData,
        rowIndex: number,
        columnIndex: number
      ) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick(data, columnData, rowIndex, columnIndex);
        event.stopPropagation();
      },
    []
  );

  const createCell = useCallback(
    (cellData: any, rowIndex: number, columnIndex: number) => {
      const data = props.values[rowIndex];
      const columnData = validColumns[columnIndex];

      if (columnData.editType === EditType.EditButton) {
        if (props.onClickAddDirectInput && rowIndex === editingRowIndex) {
          return (
            <Box className={classes.width100percent}>
              <IconButton
                color="primary"
                aria-label="save"
                onClick={handleOnClickSave(data, columnData, rowIndex, columnIndex)}
                disabled={columnData.disabled}
              >
                <Done />
              </IconButton>
            </Box>
          );
        } else {
          return (
            <Box className={classes.width100percent}>
              <IconButton
                color="primary"
                aria-label="edit"
                disabled={columnData.disabled || (props.onClickAddDirectInput && editingRowIndex !== -1)}
                onClick={handleOnClickEdit(data, columnData, rowIndex, columnIndex)}
              >
                <Edit />
              </IconButton>
            </Box>
          );
        }
      } else if (columnData.editType === EditType.ReferenceButton) {
        return (
          <Box className={classes.width100percent}>
            <IconButton
              color="primary"
              aria-label="edit"
              disabled={columnData.disabled || (props.onClickAddDirectInput && editingRowIndex !== -1)}
              onClick={handleOnClickIcon(props.onClickReference, data, columnData, rowIndex, columnIndex)}
            >
              <Info />
            </IconButton>
          </Box>
        );
      } else if (columnData.editType === EditType.DeleteButton) {
        if (props.onClickAddDirectInput && rowIndex === editingRowIndex) {
          return (
            <Box className={classes.width100percent}>
              <IconButton
                color="primary"
                aria-label="cancel"
                onClick={handleOnClickCancel(data, columnData, rowIndex, columnIndex)}
                disabled={columnData.disabled}
              >
                <CancelOutlined />
              </IconButton>
            </Box>
          );
        } else {
          return (
            <Box className={classes.width100percent}>
              <IconButton
                color="primary"
                aria-label="delete"
                disabled={columnData.disabled || (props.onClickAddDirectInput && editingRowIndex !== -1)}
                onClick={handleOnClickIcon(props.onClickDelete, data, columnData, rowIndex, columnIndex)}
              >
                <Delete />
              </IconButton>
            </Box>
          );
        }
      } else if (
        columnData.editType === EditType.AllowEdit &&
        props.onClickAddDirectInput &&
        rowIndex === editingRowIndex &&
        columnData.componentWhenEditing != null
      ) {
        return <div className={classes.inCell}>{columnData.componentWhenEditing(rowIndex)}</div>;
      } else if (columnData.rendererInCell != null) {
        return columnData.rendererInCell(data, columnData, rowIndex, columnIndex);
      } else {
        if (columnData.component == null) {
          if (columnData.convert == null) {
            return <div className={classes.inCell}>{cellData}</div>;
          } else {
            return <div className={classes.inCell}>{columnData.convert(cellData, data)}</div>;
          }
        } else {
          return <div className={classes.inCell}>{columnData.component(data, rowIndex)}</div>;
        }
      }
    },
    [
      props,
      classes.inCell,
      handleOnClickIcon,
      classes.width100percent,
      handleOnClickSave,
      handleOnClickEdit,
      handleOnClickCancel,
      editingRowIndex,
      validColumns,
    ]
  );

  const handleOnClickCell = useCallback(
    (rowIndex: number, columnIndex: number) => () => {
      props.onClickCell?.(props.values[rowIndex], validColumns[columnIndex], rowIndex, columnIndex);
    },
    [props, validColumns]
  );

  // Column.cellRenderer
  const cellRenderer = useCallback(
    ({ cellData, columnIndex, rowIndex }) => {
      return (
        <TableCell
          onClick={handleOnClickCell(rowIndex, columnIndex)}
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: !(props.hover ?? false),
          })}
          variant="body"
          style={{
            height: props.rowHeight,
          }}
          align={validColumns[columnIndex].bodyAlign ?? "left"}
        >
          {createCell(cellData, rowIndex, columnIndex)}
        </TableCell>
      );
    },
    [classes, props, createCell, handleOnClickCell, validColumns]
  );

  const handleOnContextMenu = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();

      if (props.onContextMenuHeader) {
        props.onContextMenuHeader?.(event);
      } else {
        setPopoverPosition({ top: event.clientY, left: event.clientX });
      }
    },
    [props.onContextMenuHeader]
  );

  const handleOnClickHeader = useCallback(
    (columnIndex: number) => () => {
      const sort = validColumns[columnIndex].sort;
      const dataKey = validColumns[columnIndex].dataKey;
      const convert = validColumns[columnIndex].convert;
      if (sort) {
        sort(sortDirection !== columnIndex);
      } else if (props.setValues) {
        sortWithSet(props.values, props.setValues, dataKey, sortDirection !== columnIndex, convert);
      }
      setSortDirection((value) => {
        if (value === columnIndex) {
          return -1;
        } else {
          return columnIndex;
        }
      });
    },
    [props, sortDirection, validColumns]
  );

  // Column.headerRenderer
  const headerRenderer = useCallback(
    (columnIndex: number) =>
      ({ label }: TableHeaderProps) => {
        const columnData = validColumns[columnIndex];

        let disp: JSX.Element;

        if (columnIndex === 0 && props.onClickAdd != null) {
          disp = (
            <IconButton title="追加" color="primary" aria-label="add" onClick={handleOnClickAdd}>
              <AddBox />
            </IconButton>
          );
        } else if (columnIndex === 0 && props.onClickAddDirectInput != null) {
          disp = (
            <IconButton
              title="追加"
              color="primary"
              aria-label="add"
              onClick={handleOnClickAddDirectInput}
              disabled={editingRowIndex >= 0}
            >
              <AddBox />
            </IconButton>
          );
        } else if (columnData.rendererInHeader != null) {
          disp = columnData.rendererInHeader(label, columnData, columnIndex);
        } else {
          if (columnData.headerComponent == null) {
            disp = (
              <Typography className={classes.inCell}>
                <b>{label}</b>
              </Typography>
            );
          } else {
            disp = <div className={classes.inCell}>{columnData.headerComponent(columnData)}</div>;
          }
        }

        return (
          <TableCell
            component="div"
            className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
            variant="head"
            style={{
              height: props.headerHeight,
            }}
            align={columnData.headerAlign ?? "left"}
            onClick={handleOnClickHeader(columnIndex)}
            onContextMenu={handleOnContextMenu}
          >
            {disp}
          </TableCell>
        );
      },
    [
      classes,
      validColumns,
      props.headerHeight,
      handleOnClickHeader,
      handleOnContextMenu,
      editingRowIndex,
      handleOnClickAddDirectInput,
      props.onClickAddDirectInput,
      handleOnClickAdd,
      props.onClickAdd,
    ]
  );

  // Table.rowGetter
  const handleRowGetter = useCallback(({ index }) => props.values[index], [props.values]);

  // Columnの作成
  const createColumn = useCallback(
    (outlineWidth: number) => {
      const existsFit = validColumns.find((value) => value.fit);
      let fixTotal = 0;
      let unFixTotal = 0;
      validColumns.forEach((value) => {
        if (value.widthType === "fix") {
          fixTotal += value.width;
        } else {
          unFixTotal += value.width;
        }
      });

      return validColumns.map(({ dataKey, width, fit, ...other }, index) => {
        let w = 0;
        if (existsFit) {
          w = fit && outlineWidth > totalWidth ? outlineWidth - totalWidth + width : width;
        } else {
          if (other.widthType === "fix") {
            w = width;
          } else {
            w = (width / unFixTotal) * (outlineWidth - fixTotal);
          }
        }

        return (
          <Column
            key={dataKey + String(index)}
            headerRenderer={headerRenderer(index)}
            className={classes.flexContainer}
            cellRenderer={cellRenderer}
            dataKey={dataKey ?? ""}
            width={w}
            {...other}
          />
        );
      });
    },
    [cellRenderer, classes.flexContainer, validColumns, headerRenderer, totalWidth]
  );

  useEffect(() => {
    props.setEditing?.(InputState.None);
    return () => {
      props.setEditing?.((value) => {
        props.onUnmounted?.(value);
        return InputState.None;
      });
    };
  }, [props.setEditing, props.onUnmounted]);

  const { columns, rowHeight, headerHeight, tableHeight, ...tableProps } = props;

  return (
    <>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={tableHeight ?? height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            className={classes.table}
            rowGetter={handleRowGetter}
            rowCount={props.values.length}
            {...tableProps}
            rowClassName={getRowClassName}
          >
            {createColumn(width)}
          </Table>
        )}
      </AutoSizer>
      {props.headerContext && (
        <Menu
          id="context-menu"
          anchorReference="anchorPosition"
          anchorPosition={
            popoverPosition && {
              top: popoverPosition.top,
              left: popoverPosition.left,
            }
          }
          keepMounted
          open={popoverPosition != null}
          onClose={() => {
            props.onCloseContextMenu?.(filteredColumns);

            setPopoverPosition(undefined);
          }}
        >
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            {filteredColumns.map((value, index) => {
              return (
                <FormControlLabel
                  key={index.toString()}
                  className={classes.menu}
                  control={
                    <Checkbox
                      color="primary"
                      checked={value.visible}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        props.onChangeHeaderVisible?.(event, checked, filteredColumns, index)
                      }
                    />
                  }
                  label={value.label}
                />
              );
            })}
          </Grid>
        </Menu>
      )}
    </>
  );
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 48,
  rowHeight: 48,
  onClickDelete: () => {},
  onClickReference: () => {},
};

export default React.memo(MuiVirtualizedTable);
