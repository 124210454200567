import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";

export const LoadStatus = {
  Init: 0,
  Begined: 1,
  Finished: 2,
  Error: 3,
} as const;
export type LoadStatus = (typeof LoadStatus)[keyof typeof LoadStatus];

export const abortMessage: string = "Api is being canceld.";

export interface FetchResult {
  reload: () => void;
  status: LoadStatus;
}

export const useFetch = (func: (signal: CancelTokenSource) => Promise<void>, firstLoad: boolean = true): FetchResult => {
  const [status, setStatus] = useState<LoadStatus>(firstLoad ? LoadStatus.Init : LoadStatus.Finished);

  const [call, setCall] = useState({});

  const isFirstRender = useRef(false);

  useEffect(() => {
    isFirstRender.current = true;
  }, [firstLoad]);

  useEffect(() => {
    if (isFirstRender.current !== firstLoad) {
      isFirstRender.current = false;
      return;
    }

    let dispatch = (status: LoadStatus) => setStatus(status);

    const signal = axios.CancelToken.source();

    (async () => {
      dispatch(LoadStatus.Begined);

      try {
        await func(signal);

        dispatch(LoadStatus.Finished);
      } catch (e) {
        dispatch(LoadStatus.Error);
      }
    })();

    return () => {
      dispatch = (status: LoadStatus) => {};
      signal.cancel(abortMessage);
      setStatus((value) => {
        if (LoadStatus.Finished) {
          return LoadStatus.Finished;
        } else {
          return LoadStatus.Init;
        }
      });
    };
  }, [func, firstLoad, call]);

  const reload = useCallback(() => setCall({}), []);

  return useMemo(() => {
    return { reload: reload, status: status };
  }, [reload, status]);
};

export function useExecute<T>(
  func: (unmounted: { value: boolean }, object: T) => Promise<void>
): [(object?: T) => void, boolean] {
  const [call, setCall] = useState<{ value: T }>({ value: {} as T });

  const [inProcess, setInProcess] = useState<boolean>(false);

  const isFirstRender = useRef(false);

  useEffect(() => {
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    let unmounted = { value: false };

    (async () => {
      try {
        setInProcess(true);
        await func(unmounted, call.value);
      } catch (e) {
      } finally {
        if (!unmounted.value) {
          setInProcess(false);
        }
      }
    })();

    return () => {
      unmounted.value = true;
    };
  }, [func, call]);

  return [useCallback((object?: T) => setCall({ value: object ?? ({} as T) }), []), inProcess];
}

export interface CallbackWithState<CallbackFuncType extends Function> {
  callback: CallbackFuncType;
  inProcess: boolean;
}

export function CallbackWithState<T extends Function>(callback: T, inProcess: boolean = false): CallbackWithState<T> {
  return {
    callback: callback,
    inProcess: inProcess,
  };
}
