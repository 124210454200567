import { useCallback, useState } from "react";
import { TenantModel } from "models/TenantModel";
import Api, { ApiUrl } from "common/Api";
import { useFetch } from "hooks/useFetch";

export const useTenant = (tenantGuid: string): TenantModel | undefined => {
  const [tenant, setTenant] = useState<TenantModel>();

  const load = useCallback(
    async (): Promise<void> => {
      const url = ApiUrl.Tenant(tenantGuid);
      const response = await Api.get(url);
      setTenant(response.data);
    },
    [tenantGuid]
  );

  useFetch(load);

  return tenant;
};
