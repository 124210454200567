import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, DialogTitle, FormControlLabel, Grid, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Api, { ApiUrl } from "common/Api";
import { useInputManager } from "common/HandleUtility";
import DigitsField from "common/components/DigitsField";
import { useAlertAdd } from "components/AlertList";
import TextField from "components/TextField";
import { ButtonEx } from "components/Wrapping";
import { useExecute } from "hooks/useFetch";
import { useMessageBox } from "hooks/useMessageBox";
import { PlanModel } from "models/PlanModel";
import { TenantLicenseModel } from "models/TenantLicenseModel";
import { Utility } from "utils/Utility";

type Props = {
  onClose: (result?: any) => void;
  tenantGuid: string;
  license: TenantLicenseModel;
  plans: PlanModel[];
};

export const TenantLicenseForm = (props: Props) => {
  const [tenantLicense, setTenantLicense] = useState(props.license);
  const [isValid, setIsValid] = useState<boolean>(false);

  const inputManager = useInputManager(setTenantLicense);

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();

  const isEditMode = props.license.planGuid.length > 0;
  const onCloseCallback = props.onClose;

  useEffect(() => {
    const valid = tenantLicense.planGuid !== "" && tenantLicense.count >= 0 && tenantLicense.count <= 1000;
    setIsValid(valid);
  }, [tenantLicense]);

  const [executeRegister, registerInProcess] = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        param: { tenantGuid: string; planGuid: string; license: TenantLicenseModel }
      ): Promise<void> => {
        const result = await Api.post<TenantLicenseModel>(ApiUrl.TenantLicense(param.tenantGuid), {
          planGuid: param.planGuid,
          count: param.license.count,
          isSale: param.license.isSale,
        });

        alertAdd("info", "ライセンスを登録しました");

        if (unmounted.value) {
          return;
        }

        onCloseCallback(result);
      },
      [alertAdd, onCloseCallback]
    )
  );

  const [executeUpdate, updateInProcess] = useExecute(
    useCallback(
      async (
        unmounted: { value: boolean },
        param: { tenantGuid: string; planGuid: string; license: TenantLicenseModel }
      ): Promise<void> => {
        const result = await Api.put(ApiUrl.TenantLicense(param.tenantGuid, param.planGuid), {
          count: param.license.count,
          isSale: param.license.isSale,
        });

        const isErrorResponse = Utility.isErrorResonse(result.data);
        if (!isErrorResponse) {
          alertAdd("info", "ライセンスを更新しました");
        }

        if (unmounted.value) {
          return;
        }

        if (isErrorResponse) {
          if (result.data.code === 101) {
            await messagebox.error("ライセンス情報更新エラー", "割り当て済みライセンス数を下回ってしまいます");
          }
        } else {
          onCloseCallback(result);
        }
      },
      [alertAdd, messagebox, onCloseCallback]
    )
  );

  const handleRegister = useCallback(() => {
    if (isEditMode) {
      executeUpdate({
        tenantGuid: props.tenantGuid,
        planGuid: props.license!.planGuid,
        license: tenantLicense,
      });
    } else {
      executeRegister({
        tenantGuid: props.tenantGuid,
        planGuid: tenantLicense.planGuid,
        license: tenantLicense,
      });
    }
  }, [executeRegister, executeUpdate, isEditMode, props.license, props.tenantGuid, tenantLicense]);

  return (
    <Dialog open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">{isEditMode ? "ライセンス数変更" : "ライセンス追加"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              required
              select
              id="id"
              label="プラン"
              value={tenantLicense.planGuid}
              onChange={inputManager.handleOnChange("planGuid")}
              disabled={isEditMode}
              fullWidth
            >
              {props.plans.map((plan, index) => (
                <MenuItem key={index} value={plan.guid}>
                  {plan.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <DigitsField
              type="number"
              id="licenseCount"
              label="ライセンス数"
              value={tenantLicense.count}
              inputProps={{
                maxLength: 8,
                max: 1000,
                min: 0,
              }}
              onChange={inputManager.handleOnChangeNumber("count")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tenantLicense.isSale}
                  onChange={inputManager.handleOnChangeCheck("isSale")}
                  color="primary"
                />
              }
              label="販売ライセンス"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonEx
          color="primary"
          variant="contained"
          onClick={handleRegister}
          disabled={!isValid || registerInProcess || updateInProcess}
        >
          登録
        </ButtonEx>
        <ButtonEx variant="contained" onClick={() => props.onClose()}>
          閉じる
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
