import React, { useState, useCallback } from "react";
import Api, { ApiUrl } from "common/Api";
import { PlanModel } from "models/PlanModel";
import { PlanTable } from "components/PlanManagement/PlanTable";
import { PlanForm } from "components/PlanManagement/PlanForm";
import { useMessageBox } from "hooks/useMessageBox";
import { Page, PageBody, PageHeader } from "components/Page";
import { ButtonEx } from "components/Wrapping";
import { CallbackWithState, useExecute, useFetch } from "hooks/useFetch";
import { LoadingMode, useLoadingElement } from "components/Loading";
import { useGenericStyles } from "common/Styles";
import { useAlertAdd } from "components/AlertList";

export const PlanManagement = () => {
  const [plans, setPlans] = useState<PlanModel[]>([]);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editPlan, setEditPlan] = useState<PlanModel>();

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();
  const classes = useGenericStyles();

  const load = useCallback(async (): Promise<void> => {
    const url = ApiUrl.Plan();
    const response = await Api.get(url);
    setPlans(response.data);
  }, []);

  const fetchResult = useFetch(load);
  const loadingElement = useLoadingElement(classes.loadingPageBody, LoadingMode.Circular, fetchResult);
  const reload = fetchResult.reload;

  const handleAddPlan = useCallback(() => {
    setEditPlan(undefined);
    setOpenForm(true);
  }, []);

  const handleEditPlan = useCallback((plan: PlanModel) => {
    setEditPlan(plan);
    setOpenForm(true);
  }, []);

  const handleCloseForm = useCallback(
    (result: any) => {
      setOpenForm(false);

      if (result) {
        fetchResult.reload();
      }
    },
    [fetchResult]
  );

  const deletePlan = useCallback(
    async (unmounted: { value: boolean }, param: { plan: PlanModel }) => {
      await Api.delete<PlanModel>(ApiUrl.Plan(param.plan.guid));

      alertAdd("info", "プランを削除しました");

      if (unmounted.value) {
        return;
      }

      reload();
    },
    [alertAdd, reload]
  );

  const [executeDeletePlan, deletePlanInProcess] = useExecute(deletePlan);

  const handleDeletePlan = useCallback(
    async (plan: PlanModel): Promise<void> => {
      if (await messagebox.confirm("削除確認", "プランを削除してよろしいですか？")) {
        executeDeletePlan({ plan: plan });
      }
    },
    [executeDeletePlan, messagebox]
  );

  return (
    <Page>
      <PageHeader title={"プラン管理"}>
        <ButtonEx variant="contained" color="primary" onClick={handleAddPlan}>
          追加
        </ButtonEx>
      </PageHeader>
      <PageBody>
        {loadingElement ?? (
          <>
            <PlanTable
              plans={plans}
              onEditPlan={CallbackWithState(handleEditPlan)}
              onDeletePlan={CallbackWithState(handleDeletePlan, deletePlanInProcess)}
            />
            <PlanForm open={openForm} onClose={handleCloseForm} plan={editPlan} />
          </>
        )}
      </PageBody>
    </Page>
  );
};
