import { RouteComponentProps } from "react-router-dom";
import { AreaAssignment } from "pages/AreaAssignment";
import { PlanManagement } from "pages/PlanManagement";
import { ServiceManagement } from "pages/ServiceManagement";
import { TenantLicenseManagement } from "pages/TenantLicenseManagement";
import { TenantManagement } from "pages/TenantManagement";
import { UserManagement } from "pages/UserManagement";
import { PathParameters, Url } from "utils/Url";

export type RoutableComponent = React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;

export type RouteDef = {
  path: string;
  component?: RoutableComponent;
  exact?: boolean;
}

type Routeing = {
  [index: string]: RouteDef,
}

export const Routing: Routeing = {
  Top: {
    path: "/",
    component: TenantManagement,
    exact: true,
  },
  TenantManagement: {
    path: "/tenants",
    component: TenantManagement,
    exact: true,
  },
  UserManagement: {
    path: "/tenants/:tenantGuid/users",
    component: UserManagement,
  },
  LicenseManagement: {
    path: "/tenants/:tenantGuid/licenses",
    component: TenantLicenseManagement,
  },
  ServiceManagement: {
    path: "/tenants/:tenantGuid/services",
    component: ServiceManagement,
  },
  AreaAssignment: {
    path: "/tenants/:tenantGuid/areas",
    component: AreaAssignment,
  },
  PlanManagement: {
    path: "/plans",
    component: PlanManagement,
    exact: true,
  },
}

export class RouteUrl {
  public constructor(private route: RouteDef, private parameters: PathParameters) {
  }

  public get path(): string {
    return Url.formatPath(this.route.path, this.parameters);
  }
}

export const toRouteUrl = (route: RouteDef, pathParameters: PathParameters): RouteUrl => {
  return new RouteUrl(route, pathParameters);
}
