import React, { useCallback, useState } from "react";
import Api, { ApiUrl } from "common/Api";
import { useGenericStyles } from "common/Styles";
import { useAlertAdd } from "components/AlertList";
import { LoadingMode, useLoadingElement } from "components/Loading";
import { Page, PageBody, PageHeader, formatPageTitle } from "components/Page";
import { TenantLicenseForm } from "components/TenantLicenseManagement/TenantLicenseForm";
import { TenantLicenseTable } from "components/TenantLicenseManagement/TenantLicenseTable";
import { ButtonEx } from "components/Wrapping";
import { CallbackWithState, useExecute, useFetch } from "hooks/useFetch";
import { useMessageBox } from "hooks/useMessageBox";
import { useTenant } from "hooks/useTenant";
import { PlanModel } from "models/PlanModel";
import { TenantLicenseModel } from "models/TenantLicenseModel";
import { useParams } from "react-router-dom";
import { Utility } from "utils/Utility";

const initTenantLicense: () => TenantLicenseModel = () => ({
  tenantGuid: "",
  tenantName: "",
  planGuid: "",
  planName: "",
  count: 0,
  isSale: false,
});

export const TenantLicenseManagement = () => {
  const [tenantLicenses, setTenantLicenses] = useState<TenantLicenseModel[]>([]);
  const [plans, setPlans] = useState<PlanModel[]>([]);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editTenantLicense, setEditTenantLicense] = useState(initTenantLicense);
  const [editPlans, setEditPlans] = useState<PlanModel[]>([]);

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();
  const classes = useGenericStyles();

  const { tenantGuid } = useParams<{ tenantGuid: string }>();
  const tenant = useTenant(tenantGuid);

  const load = useCallback(async (): Promise<void> => {
    const responseTenants = await Api.get(ApiUrl.TenantLicense(tenantGuid));
    setTenantLicenses(responseTenants.data);

    const responsePlans = await Api.get<PlanModel[]>(ApiUrl.Plan());
    setPlans(responsePlans.data);
  }, [tenantGuid]);

  const fetchResult = useFetch(load);
  const loadingElement = useLoadingElement(classes.loadingPageBody, LoadingMode.Circular, fetchResult);
  const reload = fetchResult.reload;

  const unassignedPlans = useCallback((): PlanModel[] => {
    return plans.filter((plan) => !tenantLicenses.some((tenantLicense) => tenantLicense.planGuid === plan.guid));
  }, [plans, tenantLicenses]);

  const handleAddLicense = useCallback(() => {
    setEditTenantLicense(initTenantLicense);
    setEditPlans(unassignedPlans());
    setOpenForm(true);
  }, [unassignedPlans]);

  const handleEditLicense = useCallback(
    (license: TenantLicenseModel) => {
      setEditTenantLicense({ ...license });
      setEditPlans(plans);
      setOpenForm(true);
    },
    [plans]
  );

  const handleCloseForm = useCallback(
    (result: any) => {
      setOpenForm(false);

      if (result) {
        fetchResult.reload();
      }
    },
    [fetchResult]
  );

  const deleteLicense = useCallback(
    async (unmounted: { value: boolean }, param: { tenantGuid: string; planGuid: string }) => {
      const result = await Api.delete(ApiUrl.TenantLicense(param.tenantGuid, param.planGuid));

      const isErrorResonse = Utility.isErrorResonse(result.data);
      if (!isErrorResonse) {
        alertAdd("info", "ライセンスを削除しました");
      }

      if (unmounted.value) {
        return;
      }

      if (isErrorResonse) {
        if (result.data.code === 101) {
          await messagebox.error("ライセンス情報削除エラー", "割り当て済みライセンスがあります");
        }
      } else {
        reload();
      }
    },
    [alertAdd, messagebox, reload]
  );

  const [executeDeletePlan, deleteLicenseInProcess] = useExecute(deleteLicense);

  const handleDeleteLicense = useCallback(
    async (license: TenantLicenseModel): Promise<void> => {
      if (await messagebox.confirm("削除確認", "ライセンスを削除してよろしいですか？")) {
        executeDeletePlan({ tenantGuid: tenantGuid, planGuid: license.planGuid });
      }
    },
    [executeDeletePlan, messagebox, tenantGuid]
  );

  return (
    <Page>
      <PageHeader
        title={formatPageTitle("ライセンス管理", tenant?.name)}
        explanation={`${tenant?.name}のライセンスを管理する画面です。`}
      >
        <ButtonEx variant="contained" color="primary" onClick={handleAddLicense}>
          追加
        </ButtonEx>
      </PageHeader>
      <PageBody>
        {loadingElement ?? (
          <>
            <TenantLicenseTable
              licenses={tenantLicenses}
              onEditLicense={CallbackWithState(handleEditLicense)}
              onDeleteLicense={CallbackWithState(handleDeleteLicense, deleteLicenseInProcess)}
            />
            {openForm && (
              <TenantLicenseForm
                onClose={handleCloseForm}
                tenantGuid={tenantGuid}
                license={editTenantLicense}
                plans={editPlans}
              />
            )}
          </>
        )}
      </PageBody>
    </Page>
  );
};
