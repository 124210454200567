import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Edit as IconEdit, Delete as IconDelete } from "@material-ui/icons";
import { TenantLicenseModel } from "models/TenantLicenseModel";
import { IconButtonEx } from "components/Wrapping";
import { CallbackWithState } from "hooks/useFetch";

export type Events = {
  onEditLicense: CallbackWithState<(license: TenantLicenseModel) => void>;
  onDeleteLicense: CallbackWithState<(license: TenantLicenseModel) => void>;
};

type Props = {
  license: TenantLicenseModel;
};

export const TenantLicenseTableRow = (props: Props & Events) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {props.license.planName}
      </TableCell>
      <TableCell>{props.license.isSale && "販売"}</TableCell>
      <TableCell>{props.license.count}</TableCell>
      <TableCell align="right">
        <IconButtonEx
          color="primary"
          title="編集"
          aria-label="編集"
          onClick={() => props.onEditLicense.callback(props.license)}
          disabled={props.onEditLicense.inProcess}
        >
          <IconEdit />
        </IconButtonEx>
        <IconButtonEx
          color="secondary"
          title="削除"
          aria-label="削除"
          onClick={() => props.onDeleteLicense.callback(props.license)}
          disabled={props.onDeleteLicense.inProcess}
        >
          <IconDelete />
        </IconButtonEx>
      </TableCell>
    </TableRow>
  );
};
