import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Url } from "utils/Url";
import { msal } from "./GoodManagementUserAgentApplication";

// export default axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL!,
//   headers: { 'Content-Type': 'application/json' },
//   responseType: 'json',
// });

const version = "/v2.0";

const ApiPath = {
  Account: version + "/account",
  Tenant: version + "/tenant/:tenantGuid",
  Agency: version + "/agency/:agencyGuid",
  Application: version + "/application/:applicationGuid",
  Plan: version + "/plan/:planGuid",
  Service: version + "/tenant/:tenantGuid/service/:serviceGuid",
  ServiceImage: version + "/tenant/:tenantGuid/service/:serviceGuid/Image",
  Services: version + "/services",
  User: version + "/tenant/:tenantGuid/user/:userGuid",
  TenantLicense: version + "/tenant/:tenantGuid/license/:planGuid",
  UserLicense: version + "/tenant/:tenantGuid/user/:userGuid/license/:planGuid",
  AreaAssign: version + "/areaassign/:guid",
  UserInviteComplete: version + "/user/InviteComplete",
};

const Optional = (param: string | undefined): string => param ?? "";

export const ApiUrl = {
  Account: () => ApiPath.Account,
  Tenant: (tenantGuid?: string) => Url.formatPath(ApiPath.Tenant, { tenantGuid: Optional(tenantGuid) }),
  Agency: (agencyGuid?: string) => Url.formatPath(ApiPath.Agency, { agencyGuid: Optional(agencyGuid) }),
  Application: (applicationGuid?: string) =>
    Url.formatPath(ApiPath.Application, { applicationGuid: Optional(applicationGuid) }),
  Plan: (planGuid?: string) => Url.formatPath(ApiPath.Plan, { planGuid: Optional(planGuid) }),
  Service: (tenantGuid: string, serviceGuid?: string) =>
    Url.formatPath(ApiPath.Service, { tenantGuid: tenantGuid, serviceGuid: Optional(serviceGuid) }),
  ServiceImage: (tenantGuid: string, serviceGuid: string) =>
    Url.formatPath(ApiPath.ServiceImage, { tenantGuid: tenantGuid, serviceGuid: serviceGuid }),
  Services: () => ApiPath.Services,
  User: (tenantGuid: string, userGuid?: string) =>
    Url.formatPath(ApiPath.User, { tenantGuid: tenantGuid, userGuid: Optional(userGuid) }),
  UserCreate: (tenantGuid: string) => Url.formatPath(ApiPath.User, { tenantGuid: tenantGuid, userGuid: "Create" }),
  UserBulkCreate: (tenantGuid: string) => Url.formatPath(ApiPath.User, { tenantGuid: tenantGuid, userGuid: "BulkCreate" }),
  UserInvite: (tenantGuid: string) => Url.formatPath(ApiPath.User, { tenantGuid: tenantGuid, userGuid: "Invite" }),
  TenantLicense: (tenantGuid: string, planGuid?: string) =>
    Url.formatPath(ApiPath.TenantLicense, { tenantGuid: tenantGuid, planGuid: Optional(planGuid) }),
  UserLicense: (tenantGuid: string, userGuid: string, planGuid?: string) =>
    Url.formatPath(ApiPath.UserLicense, { tenantGuid: tenantGuid, userGuid: userGuid, planGuid: Optional(planGuid) }),
  AreaAssignGetAreas: (tenantGuid: string) => Url.formatPath(ApiPath.AreaAssign, { guid: tenantGuid }),
  AreaAssignToPartner: () => Url.formatPath(ApiPath.AreaAssign, { guid: "" }),
  UserInviteComplete: () => ApiPath.UserInviteComplete,
};

class CallWebApi {
  axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL!,
    headers: { "Content-Type": "application/json" },
    responseType: "json",
  });

  async getAccessToken() {
    const activeAccount = msal.getActiveAccount();
    const accounts = msal.getAllAccounts();

    var response = await msal.acquireTokenSilent({
      scopes: [process.env.REACT_APP_UAA_SCOPE!],
      account: activeAccount || accounts[0],
    });

    return response.accessToken;
  }

  async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var accessToken = await this.getAccessToken();
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return await this.axiosInstance.get<T>(url, config);
  }

  async delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var accessToken = await this.getAccessToken();
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return await this.axiosInstance.delete<T>(url, config);
  }

  async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var accessToken = await this.getAccessToken();
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return this.axiosInstance.post<T>(url, data, config);
  }

  async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var accessToken = await this.getAccessToken();
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return this.axiosInstance.put<T>(url, data, config);
  }
}

const webApi: CallWebApi = new CallWebApi();

export default webApi;
