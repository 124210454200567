import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Api, { ApiUrl } from "common/Api";
import { DialogTitle, Grid, MenuItem, TextField } from "@material-ui/core";
import { PlanModel } from "models/PlanModel";
import { useMessageBox } from "hooks/useMessageBox";
import { Utility } from "utils/Utility";
import { ButtonEx } from "components/Wrapping";
import { useAlertAdd } from "components/AlertList";
import { useExecute } from "hooks/useFetch";

type Props = {
  open: boolean;
  onClose: (result?: any) => void;
  tenantGuid: string;
  userGuid: string;
  plans: PlanModel[];
};

export const PlanAssignForm = (props: Props) => {
  const [planGuid, setPlanGuid] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);

  const alertAdd = useAlertAdd();
  const messagebox = useMessageBox();

  const onCloseCallback = props.onClose;

  const onShowForm = useCallback(() => {
    setPlanGuid("");
  }, []);

  useEffect(() => {
    if (!props.open) {
      return;
    }

    onShowForm();
  }, [onShowForm, props.open]);

  useEffect(() => {
    const valid = planGuid !== "";
    setIsValid(valid);
  }, [planGuid]);

  const register = useCallback(
    async (unmounted: { value: boolean }, param: { tenantGuid: string; userGuid: string; planGuid: string }): Promise<void> => {
      const result = await Api.post(ApiUrl.UserLicense(param.tenantGuid, param.userGuid), { planGuid: param.planGuid });

      const isErrorResonse = Utility.isErrorResonse(result.data);
      if (!isErrorResonse) {
        alertAdd("info", "プランを割り当てました");
      }

      if (unmounted.value) {
        return;
      }

      if (isErrorResonse) {
        if (result.data.code === 101) {
          await messagebox.error("プラン割り当てエラー", "ライセンス数が不足しています");
        }
      } else {
        onCloseCallback(result);
      }
    },
    [alertAdd, messagebox, onCloseCallback]
  );

  const [executeRegister, registerInProcess] = useExecute(register);

  const handleRegister = useCallback(() => {
    executeRegister({
      tenantGuid: props.tenantGuid,
      userGuid: props.userGuid,
      planGuid: planGuid,
    });
  }, [planGuid, executeRegister, props.tenantGuid, props.userGuid]);

  const handlePlanChange = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const planGuid = event.target.value as string;
    setPlanGuid(planGuid);
  }, []);

  return (
    <Dialog open={props.open} maxWidth="xs">
      <DialogTitle id="form-dialog-title">使用プラン登録</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField select id="plan" label="プラン" value={planGuid} onChange={handlePlanChange} fullWidth>
              {props.plans.map((plan, index) => (
                <MenuItem key={index} value={plan.guid}>
                  {plan.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonEx color="primary" variant="contained" onClick={handleRegister} disabled={!isValid || registerInProcess}>
          登録
        </ButtonEx>
        <ButtonEx variant="contained" onClick={() => props.onClose()}>
          閉じる
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
