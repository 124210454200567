import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Api, { ApiUrl } from "common/Api";
import { AreaAssignModel } from "models/AreaAssignModel";
import { AreaAssignmentTable } from "components/AreaAssignment/AreaAssignmentTable";
import { Page, PageHeader, PageBody, PageFooter, formatPageTitle } from "components/Page";
import { useTenant } from "hooks/useTenant";
import { ButtonEx } from "components/Wrapping";
import { useExecute, useFetch } from "hooks/useFetch";
import { LoadingMode, useLoadingElement } from "components/Loading";
import { useGenericStyles } from "common/Styles";
import { useAlertAdd } from "components/AlertList";

export const AreaAssignment = () => {
  const [areaAssigns, setAreaAssigns] = useState<AreaAssignModel[]>([]);
  const [initialCheckStates, setInitialCheckStates] = useState<boolean[]>([]);
  const [isChangedCheckStates, setChangedCheckState] = useState<boolean>(false);

  const alertAdd = useAlertAdd();
  const classes = useGenericStyles();

  const { tenantGuid } = useParams<{ tenantGuid: string }>();
  const tenant = useTenant(tenantGuid);

  useEffect(() => {
    const changedCheckState = areaAssigns.some((value, index) => value.isAssigned !== initialCheckStates[index]);
    setChangedCheckState(changedCheckState);
  }, [areaAssigns, initialCheckStates]);

  const load = useCallback(async (): Promise<void> => {
    const url = ApiUrl.AreaAssignGetAreas(tenantGuid);
    const response = await Api.get<AreaAssignModel[]>(url);
    setAreaAssigns(response.data);

    const initialCheckStates = response.data.map((value) => value.isAssigned);
    setInitialCheckStates(initialCheckStates);
  }, [tenantGuid]);

  const fetchResult = useFetch(load);
  const loadingElement = useLoadingElement(classes.loadingPageBody, LoadingMode.Circular, fetchResult);
  const reload = fetchResult.reload;

  const register = useCallback(
    async (unmounted: { value: boolean }, param: { tenantGuid: string; assignAreaGuids: string[] }): Promise<void> => {
      await Api.post(ApiUrl.AreaAssignToPartner(), {
        PartnerTenantId: param.tenantGuid,
        AreaIds: param.assignAreaGuids,
      });

      alertAdd("info", "担当エリアを保存しました");

      if (unmounted.value) {
        return;
      }

      reload();
    },
    [alertAdd, reload]
  );

  const [executeRegister, registerInProcess] = useExecute(register);

  const handleAssign = useCallback(async (): Promise<void> => {
    const assignGuids = areaAssigns.filter((value) => value.isAssigned).map((value) => value.guid);
    executeRegister({ tenantGuid: tenantGuid, assignAreaGuids: assignGuids });
  }, [areaAssigns, executeRegister, tenantGuid]);

  const handleCheckRow = useCallback(
    (index: number) => {
      const newAreaAssigns = [...areaAssigns];
      newAreaAssigns[index].isAssigned = !newAreaAssigns[index].isAssigned;
      setAreaAssigns(newAreaAssigns);
    },
    [areaAssigns]
  );

  const handleCheckAll = useCallback(
    (checked: boolean) => {
      const newAreaAssigns = [...areaAssigns];
      for (let i = 0; i < newAreaAssigns.length; i++) {
        newAreaAssigns[i].isAssigned = checked;
      }
      setAreaAssigns(newAreaAssigns);
    },
    [areaAssigns]
  );

  return (
    <Page>
      <PageHeader
        title={formatPageTitle("エリア割当", tenant?.name)}
        explanation={`${tenant?.name}の担当エリアを管理する画面です。`}
      />
      <PageBody>
        {loadingElement ?? (
          <AreaAssignmentTable areaAssigns={areaAssigns} onCheckRow={handleCheckRow} onCheckAll={handleCheckAll} />
        )}
      </PageBody>
      <PageFooter>
        <ButtonEx
          variant="contained"
          color="primary"
          onClick={handleAssign}
          disabled={!isChangedCheckStates || registerInProcess}
        >
          保存
        </ButtonEx>
      </PageFooter>
    </Page>
  );
};
