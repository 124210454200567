import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Edit as IconEdit, Delete as IconDelete } from "@material-ui/icons";
import { PlanModel } from "models/PlanModel";
import { Box, Table, TableBody } from "@material-ui/core";
import { IconButtonEx } from "components/Wrapping";
import { CallbackWithState } from "hooks/useFetch";

type ServiceListProps = {
  plan: PlanModel;
};
const ServiceList = (props: ServiceListProps) => {
  return (
    <Box margin={1}>
      <Table size="small">
        <TableBody>
          {props.plan.services.map((service, index) => (
            <TableRow key={index}>
              <TableCell>{service.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export type Events = {
  onEditPlan: CallbackWithState<(plan: PlanModel) => void>;
  onDeletePlan: CallbackWithState<(plan: PlanModel) => void>;
};

type Props = {
  plan: PlanModel;
};

export const PlanTableRow = (props: Props & Events) => {
  return (
    <TableRow>
      <TableCell>{props.plan.name}</TableCell>
      <TableCell>
        <ServiceList plan={props.plan} />
      </TableCell>
      <TableCell align="right">
        <IconButtonEx
          color="primary"
          title="編集"
          aria-label="編集"
          onClick={() => props.onEditPlan.callback(props.plan)}
          disabled={props.onEditPlan.inProcess}
        >
          <IconEdit />
        </IconButtonEx>
        <IconButtonEx
          color="secondary"
          title="削除"
          aria-label="削除"
          onClick={() => props.onDeletePlan.callback(props.plan)}
          disabled={props.onDeletePlan.inProcess}
        >
          <IconDelete />
        </IconButtonEx>
      </TableCell>
    </TableRow>
  );
};
