import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Api, { ApiUrl } from "common/Api";
import { AreaComboItem, DisplayTenantCategories, TenantCategory, TenantClass } from "common/Constant";
import { useInputManager } from "common/HandleUtility";
import VirtualizedTable, { ColumnData } from "common/components/VirtualizedTable";
import { deleteButton } from "common/utility/AppUtility";
import { useAlertAdd } from "components/AlertList";
import Select from "components/Select";
import { LabelWithSelect, SelectDialog, useNarrowDown } from "components/SelectDialog";
import TextField from "components/TextField";
import { ButtonEx } from "components/Wrapping";
import { useExecute } from "hooks/useFetch";
import { TenantModel } from "models/TenantModel";
import { initTenant } from "pages/TenantManagement";

type Props = {
  open: boolean;
  onClose: (result?: any) => void;
  tenant: TenantModel; // 編集するテナント
  parentTenants?: TenantModel[]; // 親テナント一覧
  tenants: TenantModel[];
};

export const TenantForm = (props: Props) => {
  const alertAdd = useAlertAdd();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const onCloseCallback = props.onClose;

  const [tenantModel, setTenantModel] = useState<TenantModel>(initTenant);

  const inputManager = useInputManager(setTenantModel);

  const [openRelationTenant, setOpenRelationTenant] = useState(false);

  const parentTenants = useMemo(() => {
    if (props.parentTenants == null) {
      return [{ guid: "", name: "親テナントなし" }];
    } else {
      return [{ guid: "", name: "親テナントなし" }, ...props.parentTenants];
    }
  }, [props.parentTenants]);

  const handleOnClickNarrowDown = useNarrowDown(parentTenants, "name");

  const selectedRelationTenant = useMemo(() => {
    return props.tenants.filter((tenant) => tenantModel.relationTenants.some((i) => i.relationTenantGuid === tenant.guid));
  }, [props.tenants, tenantModel]);

  const filteredTenants = useMemo(() => {
    return props.tenants
      .filter((value) => value.guid !== props.tenant.guid)
      .map((value): TenantModel & { displayCategoryName: string } => {
        const text = DisplayTenantCategories.find((i) => i.value === value.displayCategory)?.text ?? "";

        (value as any)["displayCategoryName"] = text;
        return value as TenantModel & { displayCategoryName: string };
      });
  }, [props.tenants, props.tenant.guid]);

  const handleOnClickRelationTenantNarrowDown = useNarrowDown(filteredTenants, "name", "displayCategoryName");

  const selectedTenantName = useMemo(() => {
    const target = props.parentTenants?.find((value) => value.guid === tenantModel.parentGuid);

    if (target == null) {
      return "";
    }

    return target.name;
  }, [props.parentTenants, tenantModel]);

  useEffect(() => {
    if (props.open) {
      setTenantModel(props.tenant);
      setIsEditMode(props.tenant.guid != null);
    }
  }, [props.open, props.tenant]);

  const [executeRegister, registerInProcess] = useExecute(useCallback(
    async (unmounted: { value: boolean }, param: { tenantModel: TenantModel }): Promise<void> => {
      const result = await Api.post<TenantModel>(ApiUrl.Tenant(), param.tenantModel);
      alertAdd("info", "テナントを登録しました");

      if (unmounted.value) {
        return;
      }

      onCloseCallback(result);
    },
    [alertAdd, onCloseCallback]
  ));

  const [executeUpdate, updateInProcess] = useExecute(useCallback(
    async (unmounted: { value: boolean }, param: { tenantModel: TenantModel }): Promise<void> => {
      const result = await Api.put<TenantModel>(ApiUrl.Tenant(param.tenantModel.guid), param.tenantModel);
      alertAdd("info", "テナントを更新しました");

      if (unmounted.value) {
        return;
      }

      onCloseCallback(result);
    },
    [alertAdd, onCloseCallback]
  ));

  const handleRegister = useCallback(() => {
    if (isEditMode) {
      executeUpdate({
        tenantModel: tenantModel,
      });
    } else {
      executeRegister({
        tenantModel: tenantModel,
      });
    }
  }, [executeRegister, executeUpdate, isEditMode, tenantModel]);

  useEffect(() => {
    if (props.open) {
      setTenantModel((value) => {
        value.relationTenants.forEach((tenant) => {
          tenant.relationTenantName = props.tenants.find((i) => i.guid === tenant.relationTenantGuid)?.name ?? "";
        });

        return { ...value, relationTenants: [...value.relationTenants] };
      });
    }
  }, [props.open, props.tenants]);

  const handleOnSelectCategory = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: any }>, child: React.ReactNode) => {
      if (event.target.value < 0) {
        setTenantModel((value) => {
          value.category = -event.target.value;
          value.tenantClass = TenantClass.UnClass;

          return { ...value, displayCategory: event.target.value };
        });
      } else {
        setTenantModel((value) => {
          value.category = isEditMode ? tenantModel.category : TenantCategory.Partner;
          value.tenantClass = event.target.value;

          return { ...value, displayCategory: event.target.value };
        });
      }
    },
    [tenantModel]
  );

  const handleOnClickAdd = useCallback(() => setOpenRelationTenant(true), []);

  const handleOnClickDelete = useCallback((data: any, columnData: ColumnData, rowIndex: number, columnIndex: number) => {
    setTenantModel((value) => {
      value.relationTenants.splice(rowIndex, 1);
      return { ...value, relationTenants: [...value.relationTenants] };
    });
  }, []);

  const handleOnCloseRelationTenant = useCallback((result: TenantModel[] | null) => {
    setOpenRelationTenant(false);

    setTenantModel((value) => {
      if (result == null) {
        return value;
      }

      value.relationTenants = result.map((value) => ({
        relationTenantGuid: value.guid!,
        relationTenantName: value.name ?? "",
      }));

      return { ...value, relationTenants: [...value.relationTenants] };
    });
  }, []);

  const columns: ColumnData[] = [
    {
      dataKey: "name",
      label: "テナント名",
      width: 150,
      fit: true,
    },
  ];

  const relationTenantColumns: ColumnData[] = [
    deleteButton,
    {
      dataKey: "relationTenantName",
      label: "関連テナント名",
      width: 100,
      fit: true,
    },
  ];

  const selectRelationTenantColumn: ColumnData[] = [
    {
      dataKey: "name",
      label: "テナント名",
      width: 150,
      fit: true,
    },
    {
      dataKey: "displayCategoryName",
      label: "分類",
      width: 250,
    },
  ];

  return (
    <Dialog open={props.open} maxWidth="xs">
      <DialogTitle id="form-dialog-title">{isEditMode ? "テナント編集" : "テナント登録"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="テナント名称"
              value={tenantModel.name}
              inputProps={{ maxLength: 256 }}
              onChange={inputManager.handleOnChange("name")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>分類</InputLabel>
              <Select
                onChange={handleOnSelectCategory}
                value={tenantModel.displayCategory}
                disabled={isEditMode && tenantModel.category !== TenantCategory.Partner}
              >
                {DisplayTenantCategories.filter((value) =>
                  isEditMode && value.value < 0 ? value.value === -tenantModel.category : true
                ).map((value) => (
                  <MenuItem key={value.value} value={value.value}>
                    {value.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LabelWithSelect
              caption="親テナント"
              text={selectedTenantName}
              data={parentTenants}
              columns={columns}
              onClickNarrowDown={handleOnClickNarrowDown}
              onSelected={inputManager.handleOnChangeLabelWithSelect((value, result) => {
                return {
                  ...value,
                  parentGuid: result.guid,
                };
              })}
              disabled={!isEditMode && props.tenant.parentGuid != null}
              underLine
            />
            <FormHelperText disabled={!isEditMode && tenantModel.parentGuid != null}>
              代理店の場合は特約店を選択してください
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              id="area"
              label="都道府県"
              value={tenantModel.area}
              onChange={inputManager.handleOnChange("area")}
              fullWidth
            >
              {AreaComboItem.map((value, index) => {
                return (
                  <MenuItem key={value.value} value={value.value}>
                    {value.text}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField id="url" label="URL" value={tenantModel.url} onChange={inputManager.handleOnChange("url")} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ height: 200 }}>
              <VirtualizedTable
                values={tenantModel.relationTenants}
                rowHeight={48}
                columns={relationTenantColumns}
                onClickAdd={handleOnClickAdd}
                onClickDelete={handleOnClickDelete}
              />
            </Paper>
          </Grid>
        </Grid>
        <SelectDialog
          open={openRelationTenant}
          title="関連テナント"
          onClose={handleOnCloseRelationTenant}
          data={filteredTenants}
          selectedData={selectedRelationTenant}
          columns={selectRelationTenantColumn}
          onClickNarrowDown={handleOnClickRelationTenantNarrowDown}
          maxWidth="md"
        />
      </DialogContent>
      <DialogActions>
        <ButtonEx
          color="primary"
          variant="contained"
          onClick={handleRegister}
          disabled={tenantModel.name == null || tenantModel.name === "" || registerInProcess || updateInProcess}
        >
          登録
        </ButtonEx>
        <ButtonEx variant="contained" onClick={() => props.onClose()}>
          閉じる
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
