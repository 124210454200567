import React, { useState, useEffect, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ServiceModel } from "models/ServiceModel";
import Api, { ApiUrl } from "common/Api";
import { DialogTitle, Grid, Typography, TextField } from "@material-ui/core";
import { CheckList, CheckListValueConstraint } from "components/CheckList";
import { ButtonEx } from "components/Wrapping";
import { useExecute } from "hooks/useFetch";
import { useAlertAdd } from "components/AlertList";
import { ApplicationModel } from "models/ApplicationModel";
import { AttachmentImageFile } from "components/AttachmentImageFile";
import { AxiosRequestConfig } from "axios";

type Props = {
  open: boolean;
  onClose: (result?: any) => void;
  tenantGuid: string;
  service?: ServiceModel;
};

type ApplicationCheckListValue = ApplicationModel & CheckListValueConstraint;

export const ServiceForm = (props: Props) => {
  const [serviceName, setServiceName] = useState<string>("");
  const [applications, setApplications] = useState<ApplicationCheckListValue[]>([]);
  const [imageFile, setImageFile] = useState<Blob>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const alertAdd = useAlertAdd();

  const isEditMode = props.service != null;
  const onCloseCallback = props.onClose;

  const isAssignApplication = useCallback(
    (guid: string): boolean => {
      return props.service !== undefined && props.service?.applications.some((application) => application.guid === guid);
    },
    [props.service]
  );

  const onShowForm = useCallback(async () => {
    if (isEditMode) {
      setServiceName(props.service!.name);
      setApplications([]);
      setImageFile(undefined);
    } else {
      setServiceName("");
      setApplications([]);
      setImageFile(undefined);
    }

    const responseApplications = await Api.get<ApplicationModel[]>(ApiUrl.Application());

    const applications = responseApplications.data.map((application) => {
      return { ...application, checked: isAssignApplication(application.guid) };
    });
    setApplications(applications);

    if (isEditMode) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
      };
      const serviceImageResponse = await Api.get<Blob>(ApiUrl.ServiceImage(props.tenantGuid, props.service!.guid), config);

      if (serviceImageResponse.data.size === 0) {
        setImageFile(undefined);
      } else {
        setImageFile(serviceImageResponse.data);
      }
    }
  }, [isAssignApplication, isEditMode, props.service, props.tenantGuid]);

  useEffect(() => {
    if (!props.open) {
      return;
    }

    onShowForm();
  }, [onShowForm, props.open]);

  useEffect(() => {
    const valid = serviceName !== "";
    setIsValid(valid);
  }, [serviceName]);

  const register = useCallback(
    async (
      unmounted: { value: boolean },
      param: { tenantGuid: string; serviceName: string; assignApplicationIds: string[]; image?: Blob }
    ): Promise<void> => {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append("name", param.serviceName);
      for (let i = 0; i < param.assignApplicationIds.length; i++) {
        formData.append("assignApplicationIds[]", param.assignApplicationIds[i]);
      }
      if (param.image) {
        formData.append("file", param.image);
      }

      const result = await Api.post<ServiceModel>(ApiUrl.Service(param.tenantGuid), formData, config);

      alertAdd("info", "サービスを登録しました");

      if (unmounted.value) {
        return;
      }

      onCloseCallback(result);
    },
    [alertAdd, onCloseCallback]
  );

  const [executeRegister, registerInProcess] = useExecute(register);

  const update = useCallback(
    async (
      unmounted: { value: boolean },
      param: { tenantGuid: string; serviceGuid: string; serviceName: string; assignApplicationIds: string[]; image?: Blob }
    ): Promise<void> => {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append("name", param.serviceName);
      for (let i = 0; i < param.assignApplicationIds.length; i++) {
        formData.append("assignApplicationIds[]", param.assignApplicationIds[i]);
      }
      if (param.image) {
        formData.append("file", param.image);
      }

      const result = await Api.put<ServiceModel>(ApiUrl.Service(param.tenantGuid, param.serviceGuid), formData, config);

      alertAdd("info", "サービスを更新しました");

      if (unmounted.value) {
        return;
      }

      onCloseCallback(result);
    },
    [alertAdd, onCloseCallback]
  );

  const [executeUpdate, updateInProcess] = useExecute(update);

  const handleRegister = useCallback(() => {
    const assignApplicationIds = applications
      .filter((application) => application.checked === true)
      .map((application) => application.guid);
    if (isEditMode) {
      executeUpdate({
        tenantGuid: props.tenantGuid,
        serviceGuid: props.service!.guid,
        serviceName: serviceName,
        assignApplicationIds: assignApplicationIds,
        image: imageFile,
      });
    } else {
      executeRegister({
        tenantGuid: props.tenantGuid,
        serviceName: serviceName,
        assignApplicationIds: assignApplicationIds,
        image: imageFile,
      });
    }
  }, [executeRegister, executeUpdate, isEditMode, props.service, props.tenantGuid, serviceName, applications, imageFile]);

  const onCheckRow = useCallback(
    (index: number) => {
      const newApplications = [...applications];
      newApplications[index].checked = !newApplications[index].checked;
      setApplications(newApplications);
    },
    [applications]
  );

  const onCheckAll = useCallback(
    (checked: boolean) => {
      const newApplications = [...applications];
      for (let i = 0; i < newApplications.length; i++) {
        newApplications[i].checked = checked;
      }
      setApplications(newApplications);
    },
    [applications]
  );

  const handleChangeImage = useCallback((image?: Blob) => {
    setImageFile(image);
  }, []);

  return (
    <Dialog open={props.open} maxWidth="xs">
      <DialogTitle id="form-dialog-title">{isEditMode ? "サービス編集" : "サービス登録"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="サービス名称"
              defaultValue={serviceName}
              inputProps={{ maxLength: 256 }}
              onChange={(e) => setServiceName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">提供アプリ</Typography>
            <CheckList
              values={applications}
              columns={[{ key: "name", title: "アプリ名" }]}
              onCheckRow={onCheckRow}
              onCheckAll={onCheckAll}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">イメージ</Typography>
            <AttachmentImageFile
              controlId="service_image"
              style={{ width: "100%", maxHeight: "400px" }}
              onChangeImage={handleChangeImage}
              image={imageFile}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonEx
          color="primary"
          variant="contained"
          onClick={handleRegister}
          disabled={!isValid || registerInProcess || updateInProcess}
        >
          登録
        </ButtonEx>
        <ButtonEx variant="contained" onClick={() => props.onClose()}>
          閉じる
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
