import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Edit as IconEdit, Delete as IconDelete } from "@material-ui/icons";
import { ServiceModel } from "models/ServiceModel";
import { Box, Table, TableBody } from "@material-ui/core";
import { IconButtonEx } from "components/Wrapping";
import { CallbackWithState } from "hooks/useFetch";

type ApplicationListProps = {
  service: ServiceModel;
};
const ApplicationList = (props: ApplicationListProps) => {
  return (
    <Box margin={1}>
      <Table size="small">
        <TableBody>
          {props.service.applications.map((application, index) => (
            <TableRow key={index}>
              <TableCell>{application.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export type Events = {
  onEditService: CallbackWithState<(service: ServiceModel) => void>;
  onDeleteService: CallbackWithState<(service: ServiceModel) => void>;
};

type Props = {
  service: ServiceModel;
  tenantGuid: string;
};

export const ServiceTableRow = (props: Props & Events) => {
  return (
    <TableRow>
      <TableCell>{props.service.name}</TableCell>
      <TableCell>
        <ApplicationList service={props.service} />
      </TableCell>
      <TableCell align="right">
        <IconButtonEx
          color="primary"
          title="編集"
          aria-label="編集"
          onClick={() => props.onEditService.callback(props.service)}
          disabled={props.onEditService.inProcess}
        >
          <IconEdit />
        </IconButtonEx>
        <IconButtonEx
          color="secondary"
          title="削除"
          aria-label="削除"
          onClick={() => props.onDeleteService.callback(props.service)}
          disabled={props.onDeleteService.inProcess}
        >
          <IconDelete />
        </IconButtonEx>
      </TableCell>
    </TableRow>
  );
};
