import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Checkbox } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export type CheckListValueConstraint = { checked: boolean };

type Props<T extends CheckListValueConstraint> = {
  values: T[];
  columns: {
    key: keyof T;
    title: string;
    style?: React.CSSProperties;
  }[];
  onCheckRow: (index: number) => void;
  onCheckAll: (checked: boolean) => void;
};

enum CheckListState {
  // チェックなし
  NoCheck,
  // 混在
  Indeterminate,
  // 全チェック
  AllCheck,
}

const getCheckListState = <T extends CheckListValueConstraint>(values: T[]): CheckListState => {
  if (values.length === 0) {
    return CheckListState.NoCheck;
  }

  var firstState = values[0].checked;
  if (values.some((value) => value.checked !== firstState)) {
    return CheckListState.Indeterminate;
  }
  return firstState ? CheckListState.AllCheck : CheckListState.NoCheck;
};

export const CheckList = <T extends CheckListValueConstraint>(props: Props<T>) => {
  const [state, setState] = useState<CheckListState>(CheckListState.NoCheck);

  useEffect(() => {
    setState(getCheckListState(props.values));
  }, [props.values]);

  const handleCheckHeader = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onCheckAll(event.target.checked);
    },
    [props]
  );

  const handleCheckRow = useCallback(
    (index: number) => {
      props.onCheckRow(index);
    },
    [props]
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={state === CheckListState.Indeterminate}
                checked={state === CheckListState.AllCheck}
                onChange={handleCheckHeader}
              />
            </TableCell>
            {props.columns.map((column) => (
              <TableCell style={column.style} key={String(column.key)}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.values.map((value, index) => (
            <TableRow key={index}>
              <TableCell padding="checkbox">
                <Checkbox checked={value.checked} onChange={() => handleCheckRow(index)} />
              </TableCell>
              {props.columns.map((column, index) => {
                return index === 0 ? (
                  <TableCell component="th" scope="row" key={String(column.key)}>
                    {value[column.key]}
                  </TableCell>
                ) : (
                  <TableCell key={String(column.key)}>{value[column.key]}</TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
