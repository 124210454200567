import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { AddCircle as IconAsignLicense, Edit as IconEdit, Delete as IconDelete } from "@material-ui/icons";
import { UserModel } from "models/UserModel";
import { IconButtonEx } from "components/Wrapping";
import { PlanModel } from "models/PlanModel";
import { Box, Table, TableBody } from "@material-ui/core";
import { CallbackWithState } from "hooks/useFetch";

type PlanListProps = {
  user: UserModel;
  onUnassignPlan: CallbackWithState<(user: UserModel, plan: PlanModel) => void>;
};

const PlanList = (props: PlanListProps) => {
  return (
    <Box margin={1}>
      <Table size="small">
        <TableBody>
          {props.user.plans.map((plan, index) => (
            <TableRow key={index}>
              <TableCell>
                <IconButtonEx
                  color="secondary"
                  title="削除"
                  aria-label="削除"
                  onClick={() => props.onUnassignPlan.callback(props.user, plan)}
                  disabled={props.onUnassignPlan.inProcess}
                >
                  <IconDelete />
                </IconButtonEx>
                {plan.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

type Props = {
  user: UserModel;
  onAssignPlan: CallbackWithState<(user: UserModel) => void>;
  onEditUser: CallbackWithState<(user: UserModel) => void>;
  onDeleteUser: CallbackWithState<(user: UserModel) => void>;
} & PlanListProps;

export const UserTableRow = (props: Props) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" style={{ overflowWrap: "anywhere" }}>
        {props.user.userId}
      </TableCell>
      <TableCell style={{ overflowWrap: "anywhere" }}>{props.user.name}</TableCell>
      <TableCell>
        <PlanList user={props.user} onUnassignPlan={props.onUnassignPlan} />
      </TableCell>
      <TableCell align="right">
        <IconButtonEx
          color="primary"
          title="ライセンス追加"
          aria-label="ライセンス追加"
          onClick={() => props.onAssignPlan.callback(props.user)}
          disabled={props.onAssignPlan.inProcess}
        >
          <IconAsignLicense />
        </IconButtonEx>
        <IconButtonEx
          color="primary"
          title="編集"
          aria-label="編集"
          onClick={() => props.onEditUser.callback(props.user)}
          disabled={props.onEditUser.inProcess}
        >
          <IconEdit />
        </IconButtonEx>
        <IconButtonEx
          color="secondary"
          title="削除"
          aria-label="削除"
          onClick={() => props.onDeleteUser.callback(props.user)}
          disabled={props.onDeleteUser.inProcess}
        >
          <IconDelete />
        </IconButtonEx>
      </TableCell>
    </TableRow>
  );
};
