import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2),
    },
    explanation: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(2),
      whiteSpace: "pre-line",
    },
    footer: {
      marginTop: theme.spacing(0.5),
    },
    container: {
      paddingBottom: theme.spacing(1),
    },
    headerButtonArea: {
      padding: theme.spacing(2),
      "& button": {
        marginLeft: theme.spacing(1),
      },
    },
    condition: {
      paddingLeft: theme.spacing(2),
    },
  })
);

type PageHeaderProps = {
  title: string;
  explanation?: string;
  condition?: React.ReactElement;
};

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} container className={classes.header}>
      <Grid item xs={9}>
        <Grid container direction="column">
          <Grid item>
            <Typography className={classes.title} variant="h6" component="div">
              {props.title}
            </Typography>
            {props.explanation && (
              <Typography className={classes.explanation} variant="overline" component="p">
                {props.explanation}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <div className={classes.condition}>{props.condition}</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} container justify="flex-end" className={classes.headerButtonArea}>
        <Grid item>{props.children}</Grid>
      </Grid>
    </Grid>
  );
};

export const PageBody: React.FC = (props) => {
  return <Grid item>{props.children}</Grid>;
};

export const PageFooter: React.FC = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} container spacing={1} justify="flex-end" alignItems="center" className={classes.footer}>
      <Grid item>{props.children}</Grid>
    </Grid>
  );
};

export const Page: React.FC = (props) => {
  const classes = useStyles();

  return (
    <Paper>
      <Grid container direction="column" alignItems="stretch" spacing={1} className={classes.container}>
        {props.children}
      </Grid>
    </Paper>
  );
};

export function formatPageTitle(pageName: string, targetName?: string): string {
  if (targetName === undefined) {
    return pageName;
  }
  return `${pageName} - ${targetName}`;
}
