import React from "react";
import { SvgIcon } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import AccountBox from "@material-ui/icons/AccountBox";
import Store from "@material-ui/icons/Store";
import { RouteDef, Routing } from "common/Routing";
import { LinkTo } from "components/Navigate";

const drawerWidth = 180;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
    },
    itemIcon: {
      minWidth: 32,
    },
  })
);

type MenuItem = {
  title: string;
  icon: typeof SvgIcon;
  route: RouteDef;
};

const menuItems: MenuItem[] = [
  {
    title: "テナント管理",
    icon: AccountBox,
    route: Routing.TenantManagement,
  },
  {
    title: "プラン管理",
    icon: Store,
    route: Routing.PlanManagement,
  },
];

export const Menu = () => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem button component={LinkTo} route={item.route} key={index}>
              <ListItemIcon className={classes.itemIcon}>
                <SvgIcon component={item.icon} />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};
