import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { PlanModel } from "models/PlanModel";
import { PlanTableRow, Events as TableRowEvents } from "./PlanTableRow";

type Props = {
  plans: PlanModel[];
} & TableRowEvents;

export const PlanTable = (props: Props) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>プラン名称</TableCell>
            <TableCell style={{ width: 300 }}>提供サービス</TableCell>
            <TableCell style={{ width: 140 }}>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.plans.map((plan) => (
            <PlanTableRow
              key={plan.guid}
              plan={plan}
              onEditPlan={props.onEditPlan}
              onDeletePlan={props.onDeletePlan}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
