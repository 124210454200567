import React, { useReducer, useContext } from "react";

export interface IAction {
  type: string;
  value: any;
}

export function connect<T>(reducer: (state: T, action: IAction) => T, initialState: T) {
  const stateContext = React.createContext(initialState);
  const dispatchContext = React.createContext((() => true) as React.Dispatch<IAction>);

  const Provider = (props: { children: JSX.Element }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
      <dispatchContext.Provider value={dispatch}>
        <stateContext.Provider value={state}>{props.children}</stateContext.Provider>
      </dispatchContext.Provider>
    );
  };

  const useDispatch = () => {
    return useContext(dispatchContext);
  };

  const useGlobalState = <K extends keyof T>(property: K) => {
    const state = useContext(stateContext);
    return state[property];
  };

  return { Provider, useDispatch, useGlobalState };
}
